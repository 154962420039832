export enum Gang {
  "Escher",
  "Goliath",
  "Orlock",
  "Van Saar",
  "Cawdor",
  "Delaque",
}

export enum GangOrOther {
  "Escher",
  "Goliath",
  "Orlock",
  "Van Saar",
  "Cawdor",
  "Delaque",
  "Other",
}
