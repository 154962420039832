import { createContext, useContext } from "react";
import {
  Battle,
  Campaign,
  CampaignSummary,
  CampaignWeek,
  User,
} from "../types";
import {
  BattleRequest,
  BattleResultRequest,
  CampaignRequest,
  CampaignWeekReqest,
} from "../types/requests";

export interface ApiContextType {
  login: (email: string, password: string) => Promise<void>;
  forgottenPassword: (email: string) => Promise<void>;
  resetPassword: (
    email: string,
    password: string,
    token: string,
  ) => Promise<void>;
  logout: () => Promise<void>;
  register: (email: string, name: string, password: string) => Promise<void>;
  updateAccount: (
    email: string,
    name: string,
    password?: string,
  ) => Promise<void>;
  userList: (email: string) => Promise<User[]>;
  createCampaign: (campaign: CampaignRequest) => Promise<string | undefined>;
  listCampaigns: () => Promise<CampaignSummary[]>;
  getCampaign: (campaignId: string) => Promise<Campaign | undefined>;
  createBattle: (
    campaignId: string,
    battle: BattleRequest,
  ) => Promise<Battle | undefined>;
  deleteBattle: (campaignId: string, batteId: string) => Promise<void>;
  updateBatteResult: (
    campaignId: string,
    batteId: string,
    battleResult: BattleResultRequest,
  ) => Promise<void>;
  startNextCampaignWeek: (
    campaignId: string,
    campaignWeek: CampaignWeekReqest,
  ) => Promise<CampaignWeek | undefined>;
  endCampaign: (campaignId: string) => Promise<void>;
}

const ApiContext = createContext<ApiContextType | undefined>(undefined);

const useApiContext = () => {
  // get the context
  const context = useContext(ApiContext);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error("useApiContext was used outside of its Provider");
  }

  return context;
};

export { ApiContext, useApiContext };
