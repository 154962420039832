import { Button, Form, Modal } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";

import FormikFormInput from "./formikFormInput";

import { Dome } from "../types";

export interface DomeFormProps {
  dome: Dome | undefined;
  onCancel: () => void;
  onSubmit: (value: Dome) => void;
}

const validationSchema = Yup.object({
  name: Yup.string().required(),
});

const DomeForm = ({ dome, onCancel, onSubmit }: DomeFormProps) => {
  return (
    <>
      {dome && (
        <Modal show={true} onHide={onCancel} backdrop="static" scrollable>
          <Formik
            initialValues={dome}
            validationSchema={validationSchema}
            onSubmit={onSubmit}>
            {({ handleSubmit, isValid }) => (
              <Form
                noValidate
                onSubmit={handleSubmit}
                className="modal-content">
                <Modal.Header closeButton>
                  <Modal.Title>Edit Dome</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <FormikFormInput
                    name="name"
                    type="text"
                    label="Name"
                    autoComplete="off"
                  />
                </Modal.Body>
                <Modal.Footer>
                  {isValid}
                  <Button variant="secondary" onClick={onCancel}>
                    Cancel
                  </Button>
                  <Button variant="primary" disabled={!isValid} type="submit">
                    Save
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
    </>
  );
};

export default DomeForm;
