import { Button, Dropdown, ListGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEye,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import { Dome } from "../types";

import { useSetupContext } from "../pages/setup/setupContext";

export interface DomeItemProps {
  dome: Dome;
  slot: number;
  selectedTerritoryId: number | undefined;
  onTerritorySelect: (territoryId: number) => void;
  onTerritoryAdd: (territoryId: number, domeId: number, slot: number) => void;
  onTerritoryRemove: (territoryId: number) => void;
  onTerritoryEdit: (territoryId: number) => void;
}

const DomeItem = ({
  dome,
  slot,
  selectedTerritoryId,
  onTerritorySelect,
  onTerritoryAdd,
  onTerritoryRemove,
  onTerritoryEdit,
}: DomeItemProps) => {
  const { value: wizardValue } = useSetupContext();

  const assignedTerritory = wizardValue.campaignTerritories.find(
    (t) => t.domeId === dome.id && t.slot === slot,
  );

  return (
    <ListGroup.Item
      active={selectedTerritoryId === assignedTerritory?.id}
      className="user-select-none d-flex align-items-center"
      style={{ zIndex: "unset" }}>
      <span className="round-pill me-2">{String.fromCharCode(64 + slot)}</span>
      {assignedTerritory !== undefined && (
        <>
          <span className="flex-grow-1">{assignedTerritory.name}</span>
          <Button
            className="px-2 py-0"
            style={{ height: 24 }}
            onClick={() => onTerritorySelect(assignedTerritory.id)}>
            <FontAwesomeIcon icon={faEye} />
          </Button>
          <Button
            className="px-2 py-0"
            style={{ height: 24 }}
            onClick={() => onTerritoryEdit(assignedTerritory.id)}>
            <FontAwesomeIcon icon={faEdit} />
          </Button>
          <Button
            className="px-2 py-0"
            style={{ height: 24 }}
            onClick={() => onTerritoryRemove(assignedTerritory.id)}>
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </>
      )}
      {assignedTerritory === undefined && (
        <>
          <span className="text-body-tertiary flex-grow-1">Empty</span>
          {wizardValue.campaignTerritories.some(
            (t) => t.domeId === -1 || t.slot === -1,
          ) && (
            <Dropdown>
              <Dropdown.Toggle className="px-2 py-0" style={{ height: 24 }}>
                <FontAwesomeIcon icon={faPlus} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {wizardValue.campaignTerritories
                  .filter((t) => t.domeId === -1 || t.slot === -1)
                  .map((t) => (
                    <Dropdown.Item
                      key={t.id}
                      onClick={() => onTerritoryAdd(t.id, dome.id, slot)}>
                      {t.name}
                    </Dropdown.Item>
                  ))}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </>
      )}
    </ListGroup.Item>
  );
};

export default DomeItem;
