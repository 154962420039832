import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, Card, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";

import LoadingSpinner from "../components/loadingSpinner";

import { useApiContext } from "../api/apiContext";

const loginSchema = yup.object({
  email: yup
    .string()
    .email("Must be a valid email")
    .required("Email is required"),
  password: yup.string().required("Password is required"),
});

const registerSchema = yup.object({
  email: yup
    .string()
    .email("Must be a valid email")
    .required("Email is required"),
  name: yup.string().required("Name is required"),
  password: yup
    .string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      "Password not complex enough",
    )
    .required("Password is required"),
  password2: yup
    .string()
    .oneOf([yup.ref("password"), ""], "Passwords must match")
    .required("Please repeat your password"),
  terms: yup
    .boolean()
    .oneOf([true], "The terms and conditions must be accepted"),
});

const Login = () => {
  const apiContext = useApiContext();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);

  const handleLoginSubmit = (values: { email: string; password: string }) => {
    setLoading(true);
    apiContext
      .login(values.email, values.password)
      .then(() => navigate(searchParams.get("return") ?? "/"))
      .finally(() => setLoading(false));
  };

  const handleRegisterSubmit = (values: {
    email: string;
    name: string;
    password: string;
    password2: string;
    terms: boolean;
  }) => {
    setLoading(true);
    apiContext
      .register(values.email, values.name, values.password)
      .then(() => navigate(searchParams.get("return") ?? "/"))
      .finally(() => setLoading(false));
  };

  return (
    <>
      {loading && <LoadingSpinner />}
      <div className="d-flex align-items-stretch">
        <Formik
          validationSchema={loginSchema}
          onSubmit={handleLoginSubmit}
          initialValues={{ email: "", password: "" }}>
          {({
            handleChange,
            values,
            errors,
            handleBlur,
            touched,
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit} className="flex-fill mx-3">
              <Card className="h-100">
                <Card.Header>Login</Card.Header>
                <Card.Body>
                  <Form.Group className="position-relative mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Email"
                      onChange={handleChange}
                      value={values.email}
                      isInvalid={touched.email && !!errors.email}
                      onBlur={handleBlur}
                      name="email"
                    />
                    <Form.Control.Feedback type="invalid" tooltip={true}>
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="position-relative mb-3">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      onChange={handleChange}
                      value={values.password}
                      isInvalid={touched.password && !!errors.password}
                      onBlur={handleBlur}
                      name="password"
                    />
                    <Form.Control.Feedback type="invalid" tooltip={true}>
                      {errors.password}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Button
                    variant="link"
                    onClick={() => navigate("/forgotpassword")}>
                    Forgotten Password?
                  </Button>
                </Card.Body>
                <Card.Footer className="text-end">
                  <Button variant="primary" type="submit" disabled={loading}>
                    Login
                  </Button>
                </Card.Footer>
              </Card>
            </Form>
          )}
        </Formik>

        <Formik
          validationSchema={registerSchema}
          onSubmit={handleRegisterSubmit}
          initialValues={{
            email: "",
            name: "",
            password: "",
            password2: "",
            terms: false,
          }}>
          {({
            handleChange,
            values,
            errors,
            handleBlur,
            touched,
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit} className="flex-fill mx-3">
              <Card>
                <Card.Header>Register</Card.Header>
                <Card.Body>
                  <Form.Group className="position-relative mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Email"
                      onChange={handleChange}
                      value={values.email}
                      isInvalid={touched.email && !!errors.email}
                      onBlur={handleBlur}
                      name="email"
                    />
                    <Form.Control.Feedback type="invalid" tooltip={true}>
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="position-relative mb-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      onChange={handleChange}
                      value={values.name}
                      isInvalid={touched.name && !!errors.name}
                      onBlur={handleBlur}
                      name="name"
                    />
                    <Form.Control.Feedback type="invalid" tooltip={true}>
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="position-relative mb-3">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      onChange={handleChange}
                      value={values.password}
                      isInvalid={touched.password && !!errors.password}
                      onBlur={handleBlur}
                      name="password"
                    />
                    <Form.Control.Feedback type="invalid" tooltip={true}>
                      {errors.password}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="position-relative mb-3">
                    <Form.Label>Retype Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Retype password"
                      onChange={handleChange}
                      value={values.password2}
                      isInvalid={touched.password2 && !!errors.password2}
                      onBlur={handleBlur}
                      name="password2"
                    />
                    <Form.Control.Feedback type="invalid" tooltip={true}>
                      {errors.password2}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="position-relative mb-3">
                    <Form.Check
                      type="checkbox"
                      label=""
                      onChange={handleChange}
                      checked={values.terms}
                      onBlur={handleBlur}
                      isInvalid={touched.terms && !!errors.terms}
                      name="terms">
                      <Form.Check.Input
                        type="checkbox"
                        onChange={handleChange}
                        checked={values.terms}
                        onBlur={handleBlur}
                        name="terms"
                        isInvalid={touched.terms && !!errors.terms}
                      />
                      <Form.Check.Label>
                        I accept the <a href="/terms">terms and conditions</a>{" "}
                        of use
                      </Form.Check.Label>
                      <Form.Control.Feedback type="invalid" tooltip={true}>
                        {errors.terms}
                      </Form.Control.Feedback>
                    </Form.Check>
                  </Form.Group>
                </Card.Body>
                <Card.Footer className="text-end">
                  <Button variant="primary" type="submit" disabled={loading}>
                    Register
                  </Button>
                </Card.Footer>
              </Card>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default Login;
