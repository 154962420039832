import { useEffect, useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { FieldArray, Formik } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash, faUser } from "@fortawesome/free-solid-svg-icons";

import FormikFormInput from "./formikFormInput";
import FormikFormSelect from "./formikFormSelect";

import { Player, Territory } from "../types";
import { BattleRequest } from "../types/requests";

export interface AddBattleFormProps {
  show: boolean;
  players: Player[];
  availableTerritories: Territory[];
  onCancel: () => void;
  onSubmit: (value: BattleRequest) => void;
}

const validationSchema = Yup.object({
  players: Yup.array()
    .of(Yup.string().required("Please select the player"))
    .min(2, "There must be at least 2 players")
    .test("unique", "Duplicate player", (value) =>
      value ? value.length === new Set(value)?.size : true,
    )
    .required("Players are required"),
  date: Yup.date().required("Date is required"),
  territoryId: Yup.number().optional(),
});

const AddBattleForm = ({
  show,
  players,
  availableTerritories,
  onCancel,
  onSubmit,
}: AddBattleFormProps) => {
  const [battle, setBattle] = useState<BattleRequest | undefined>();

  useEffect(() => {
    setBattle({
      players: ["", ""],
      date: "",
      territoryId: undefined,
    });
  }, [show]);

  return (
    <>
      {show && battle && (
        <Modal show={true} onHide={onCancel} backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>Record a Battle</Modal.Title>
          </Modal.Header>

          <Formik
            initialValues={battle}
            validationSchema={validationSchema}
            onSubmit={onSubmit}>
            {({ values, handleSubmit, isValid, setFieldValue }) => (
              <Form
                noValidate
                onSubmit={handleSubmit}
                className="modal-content">
                <Modal.Body>
                  <FieldArray
                    name="players"
                    render={(arrayHelpers: any) => (
                      <>
                        {values.players.map((_, index) => (
                          <InputGroup key={index}>
                            <FormikFormSelect
                              name={`players.${index}`}
                              options={players.map((p) => ({
                                value: p.user.userId,
                                label: `${p.gangName} (${p.user.name})`,
                              }))}
                              label={`Player ${index + 1}`}
                              autoComplete="off"
                            />
                            <Button
                              type="button"
                              style={{ height: 58 }}
                              disabled={values.players.length <= 2}
                              onClick={() => arrayHelpers.remove(index)}>
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          </InputGroup>
                        ))}
                        <Button
                          size="sm"
                          className="mb-3"
                          disabled={values.players.length >= players.length}
                          onClick={() =>
                            setFieldValue(
                              "players",
                              [...values.players, ""],
                              true,
                            )
                          }>
                          <FontAwesomeIcon icon={faPlus} />
                          <FontAwesomeIcon icon={faUser} /> Add Player
                        </Button>
                      </>
                    )}
                  />
                  <FormikFormInput
                    name="date"
                    type="datetime-local"
                    label="Date"
                    autoComplete="off"
                  />
                  <FormikFormSelect
                    name="territoryId"
                    options={availableTerritories.map((t) => ({
                      value: t.id,
                      label: t.name,
                    }))}
                    nullOptionDisabled={false}
                    nullOptionLabel="No territory"
                    label="Challenge Territory"
                    autoComplete="off"
                  />
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={onCancel}>
                    Cancel
                  </Button>
                  <Button variant="primary" disabled={!isValid} type="submit">
                    Save
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
    </>
  );
};

export default AddBattleForm;
