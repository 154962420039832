import { BoonType } from "./boonType";
import { Gang } from "./gang";
import Territory from "./territory";
import { TerritoryGraphic } from "./territoryGraphic";

export const DefaultTerritories: Territory[] = [
  // Common
  {
    id: 1,
    name: "Old Ruins",
    graphic: TerritoryGraphic["Old Ruins"],
    flavourText:
      "A complex of old ruins stands tall on the tangled landscape of the underhive. Beneath the ashes and rubble, the tattered treasures of past ages wait.",
    boon: [
      {
        type: BoonType.Income,
        text: "The gang earns D3x10 credits from this Territory when collecting income.  Additionally, add +1 to the D3 result for each Dome Runner attached to the gang.",
      },
    ],
  },
  {
    id: 2,
    name: "Settlement",
    graphic: TerritoryGraphic.Settlement,
    flavourText:
      "Settlements dot the underhive. Most are scrap-shanties, peopled by the brave, the foolish and the desperate.",
    boon: [
      {
        type: BoonType.Income,
        text: "The gang earns D6x10 credits from this Territory when collecting income.",
      },
      {
        type: BoonType.Reputation,
        text: "Whilst it controls this Territory, the gang add +1 to its Reputation.",
      },
      {
        type: BoonType.Recruit,
        text: "The gang may choose to roll two D6 after every battle.  On a role of 6 on either dice, the gang may recruit a single Juve from their House List for free.  If both dice come up as 6, then the gang may recruit a Ganger from their House List for free.",
      },
    ],
  },
  {
    id: 3,
    name: "Rogue Doc Shop",
    graphic: TerritoryGraphic["Rogue Doc"],
    flavourText:
      "A sawbones, blood cleanser or chirurgeon has set up shop. Any gang willing to provide this rogue healer with protection can count on their loyal service.",
    boon: [
      {
        type: BoonType.Recruit,
        text: "The gang may recruit a Rogue Doc Hanger-on for free.",
      },
    ],
  },
  {
    id: 4,
    name: "Promethium Cache",
    graphic: TerritoryGraphic["Promethium Cache"],
    flavourText:
      "Stacks of fuel drums and tanks fill this half-forgotten reach of the underhive, enough to keep a factory running, or burn half the Sump down if a stray spark catches it.",
    boon: [
      {
        type: BoonType.Equipment,
        text: "Whilst it controls this Territory, three fighters in the gang gain Incendiary Charges for free.",
      },
      {
        type: BoonType.Special,
        text: "All fighters in the gang may re-roll Ammo checks for any weapon that has the Blaze trait.",
      },
    ],
  },
  {
    id: 5,
    name: "Wastes",
    graphic: TerritoryGraphic.Wastes,
    flavourText:
      "Tracts of ash, shapeless rubble, and twisted metal structures run though the underhive, their size waxing and waning with the ages.  Most gangs have to passthough a portion of these wastes as they travel between places of more value.",
    boon: [
      {
        type: BoonType.Special,
        text: "If challenged in the Occupation phase, the gang may choose the Territory at stake in the battle, even though it would normally be chosen by the challenger. If challenged in the Takeover phase for a Territory the gang already controls, make an Intelligence check for the gang Leader.  If the check is passed, the player of the gang may choose to play the Ambush scenario instead of rolling.  They are automatically the attacker.",
      },
    ],
  },
  {
    id: 6,
    name: "Sludge Sea",
    graphic: TerritoryGraphic["Sludge Sea"],
    flavourText:
      "Effluent and polluted water drains down though the have to catch in brown pools, forming lakes of fuming, iridescent sludge.",
    boon: [
      {
        type: BoonType.Equipment,
        text: "Whilst it control this Territory, three fighters in the gang gain Choke Gas Grenades for free.",
      },
    ],
  },
  {
    id: 7,
    name: "Workshop",
    graphic: TerritoryGraphic.Workshop,
    flavourText:
      "Underhive workshops tend to be a combination of different scrap yards, festooned with belts of ammo and strewn with the carcasses of machines. The denizens of such places are often strange recluses, who mutter to their machines and create strange totems out of rusting treasure.",
    boon: [
      {
        type: BoonType.Recruit,
        text: "The gang may recruit an Ammo-jack Hanger-on for free.",
      },
    ],
  },
  {
    id: 8,
    name: "Collapsed Dome",
    graphic: TerritoryGraphic["Collapsed Dome"],
    flavourText:
      "Many of the ancient domes that honeycomb the underhive eventually collapse under the weight of time, leaving treasures trapped beneath the wreckage and the structure above liable to fall apart at the smallest misstep.",
    boon: [
      {
        type: BoonType.Income,
        text: "When collecting income from this Territory, the controlling player may choose to roll between 2D6x10 and 6D6x10. However, if a double is rolled,then no income is generated and a random fighter from the gang suffers a Lasting Injury.",
      },
    ],
  },

  // Cawdor
  {
    id: 9,
    name: "Refuse Drift",
    gang: Gang.Cawdor,
    graphic: TerritoryGraphic["Refuse Drift"],
    flavourText:
      "Vast agglomerations of refuse gather in areas of the underhive in drifts that shift like dunes in the desert.",
    boon: [
      {
        type: BoonType.Income,
        text: "The gang earns 2D6x5 credits from this Territory when collecting income.  However, if a double is rolled, a randomly determined fighter has a nasty encounter with a waste-lurker and must miss the next battle whilst they recover. No income is lost.",
      },
    ],
    enhancedBoon: [
      {
        type: BoonType.Income,
        text: "The gang earns 2D6x5 credits from this Territory when collecting income. A Cawdor gang has no risk of encountering a nasty waste-lurker.",
      },
    ],
  },
  {
    id: 10,
    name: "Corpse Farm",
    gang: Gang.Cawdor,
    graphic: TerritoryGraphic["Corpse Farm"],
    flavourText:
      "Everyone must eat, and the corpse farm takes the flesh of the dead and makes it into corpse-starch that feeds the hive. The underhive corpse farms d0 a brisk, if shunned, trade in bodies of the dead.",
    boon: [
      {
        type: BoonType.Income,
        text: "When collecting income, the gang gains D6x10 credits for every fighter on either side that was deleted from their roster during the Update Roster step ofthe preceding battle.",
      },
    ],
    enhancedBoon: [
      {
        type: BoonType.Reputation,
        text: "Whilst it controls this Territory, the gang add +1 to its Reputation.",
      },
      {
        type: BoonType.Income,
        text: "When collecting income, the gang gains D6x10 credits for every fighter on either side that was deleted from their roster during the Update Roster step ofthe preceding battle.",
      },
    ],
  },
  {
    id: 11,
    name: "Bone Shrine",
    gang: Gang.Cawdor,
    graphic: TerritoryGraphic["Bone Shrine"],
    flavourText:
      "Shrines of bones and noose-ropes are common in the underhive, and even the most cold-blooded ganger will rarely pass one without throwing in a cred as a plea for a blessing.",
    boon: [
      {
        type: BoonType.Income,
        text: "The gang earns 2D6x5 credits from this Territory when collecting income.",
      },
    ],
    enhancedBoon: [
      {
        type: BoonType.Reputation,
        text: "Whilst it controls this Territory, the gang add +2 to its Reputation.",
      },
      {
        type: BoonType.Income,
        text: "The gang earns 4D6x5 credits from this Territory when collecting income.",
      },
    ],
  },

  // Delaque
  {
    id: 12,
    name: "Drinking Hole",
    gang: Gang.Delaque,
    graphic: TerritoryGraphic["Drinking Hole"],
    flavourText:
      "Reeking of liquor, sweat and blood, a drinking den is where many hivers go to numb their sorrows, or to find their courage at the bottom of Wildsnake or Second Best.",
    boon: [
      {
        type: BoonType.Reputation,
        text: "Whilst it controls this Territory, the gang add +1 to its Reputation.",
      },
      {
        type: BoonType.Special,
        text: "Whilst it controls this Territory, any fighter in the gang may re-rolland failed Cool checks. If a fighter uses this option, place a marker on their card to show that they have hit the bottle.  The marked fighters suffer a -1 to hit penalty for the rest of the battle.",
      },
    ],
    enhancedBoon: [
      {
        type: BoonType.Reputation,
        text: "Whilst it controls this Territory, the gang add +2 to its Reputation.",
      },
      {
        type: BoonType.Income,
        text: "A Delague gang may not use the standard Boon.  Instead, the player of the Delague gang that controls this Territory may nominate three enemy fighters at the start of the battle, and places an Intoxicated marker on each fighter's card to show that their drink was spiked. The marked fighters suffer -1 to all tests and checks for the duration of the battle.",
      },
    ],
  },
  {
    id: 13,
    name: "Gambling Den",
    gang: Gang.Delaque,
    graphic: TerritoryGraphic["Gambling Den"],
    flavourText:
      "Gambling is rife in the underhive with fortunes won on the turn of a card and lost on the spin of a bolt shell, and debts are easily piled up by those who don't know when to quit.",
    boon: [
      {
        type: BoonType.Reputation,
        text: "Whilst it controls this Territory, the gang add +1 to its Reputation.",
      },
      {
        type: BoonType.Income,
        text: "The player chooses a suit of cards. The player then draws a card from a shuffled deck of playing cards that includes both Jokers. If they draw a card from the suit they choose, then earn income to the value of the card (Jack 11, Queen 12, King 12, Ace 14) x10 credits. If they draw a card from the suit of the same colour, then the income is that value of the card x5 credits. If it is any other suit, they gain no income for the Territory. If however, they draw a Joker, they must pay all of the income they earn in that post-battle sequence to a random gang taking part in the campaign, as determined by the Arbitrator.",
      },
    ],
    enhancedBoon: [
      {
        type: BoonType.Reputation,
        text: "Whilst it controls this Territory, the gang add +2 to its Reputation.",
      },
      {
        type: BoonType.Special,
        text: "The Delague player that controls this Territory, the gang may nominate a single enemy fighter at the start of the battle.  The Delaque have called in the fighter's debt marker, and in return for keeping all of their limbs intact, the fighter agrees to take no part in the comming battle. The nominated fighter misses the battle.",
      },
    ],
  },
  {
    id: 14,
    name: "Needle Ways",
    gang: Gang.Delaque,
    graphic: TerritoryGraphic["Needle Ways"],
    flavourText:
      "The Needle Ways are the vents, crawl tunnels and pipes that thread though the mass of the hive. Those that can navigate them can pass unseen between almost anywhere.",
    boon: [
      {
        type: BoonType.Special,
        text: 'Whilst it controls this Territory, the gang may infiltrate up to three fighters onto the battlefield ahead of any battle. Inflitrating fighters must be part of the crew for the battle, but instead of being set up on the battlefield, they are placed to the side.  At the end of the first round, the controlling player nominates any spot on the ground surface of the battlefield and sets up each infiltrating fighter within 2" of that spot.',
      },
    ],
    enhancedBoon: [
      {
        type: BoonType.Special,
        text: "A Delaque gang that controls this Territory may infiltrate two groups of up to three fighters using the method above. The fighters in each group must be specified before the battle.",
      },
    ],
  },

  // Escher
  {
    id: 15,
    name: "Synth Still",
    gang: Gang.Escher,
    graphic: TerritoryGraphic["Synth Still"],
    flavourText:
      "Constructed from tangles of reclaimed pipes, vats and cylinders, a synth still in skilled hands can produce the finest poisons, medicines and narcotics.",
    boon: [
      {
        type: BoonType.Special,
        text: "Whilst it controls this territory, the gang treats chem-synths, medicae kits, stimm-slug stashes and any weapon with the Gas or Toxin trait as Common.",
      },
    ],
    enhancedBoon: [
      {
        type: BoonType.Reputation,
        text: "Whilst it controls this Territory, the gang add +1 to its Reputation.",
      },
      {
        type: BoonType.Special,
        text: "Whilst it controls this territory, the gang treats chem-synths, medicae kits, stimm-slug stashes and any weapon with the Gas or Toxin trait as Common, and halves the cost of these items (roudning up).",
      },
    ],
  },
  {
    id: 16,
    name: "Stinger Mould Sprawl",
    gang: Gang.Escher,
    graphic: TerritoryGraphic["Stinger Mould Sprawl"],
    flavourText:
      "Stinger mould is a rare fungus that grows where rad-wastes has saturated a fungus sprawl.  The fungus can be deadly, but also offers near-miraculous healing properties.",
    boon: [
      {
        type: BoonType.Special,
        text: "During the post-battle sequence, the gang controlling this Territory may re-roll a single Lasting Injury roll on a fighter. Note that a Memorable Death result may not be re-rolled.",
      },
    ],
    enhancedBoon: [
      {
        type: BoonType.Reputation,
        text: "Whilst it controls this Territory, the gang add +1 to its Reputation.",
      },
      {
        type: BoonType.Special,
        text: "An Escher gang may either (1) remove a single existing Lasting Injury from a fighter, or (2) re-roll a single Lasting Injury roll on a fighter, including a Memorable Death result.",
      },
    ],
  },
  {
    id: 17,
    name: "Narco Den",
    gang: Gang.Escher,
    graphic: TerritoryGraphic["Narco Den"],
    flavourText:
      "Narco dens are filled with the scent of exotic smoke, and the bubbles and hiss of fume-bottles and chem-injectors. Rich and poor alike come to such places to chase dreams and forget nightmares.",
    boon: [
      {
        type: BoonType.Income,
        text: "The gang earns D6x5 credits from this Territory when collecting income.",
      },
    ],
    enhancedBoon: [
      {
        type: BoonType.Reputation,
        text: "Whilst it controls this Territory, the gang add +1 to its Reputation.",
      },
      {
        type: BoonType.Income,
        text: "The gang earns D6x5 credits from this Territory when collecting income. If the gang also controls a Synth Still, this is increase to D6x10.",
      },
    ],
  },

  // Goliath
  {
    id: 18,
    name: "Slag Furnace",
    gang: Gang.Goliath,
    graphic: TerritoryGraphic["Slag Furnace"],
    flavourText:
      "Glowing with heat and splattered with molten metal, a slag furnace smelts slag and ore so that the metals they contain can be poured off.",
    boon: [
      {
        type: BoonType.Income,
        text: "The gang earns D6x5 credits from this Territory when collecting income.",
      },
    ],
    enhancedBoon: [
      {
        type: BoonType.Reputation,
        text: "Whilst it controls this Territory, the gang add +2 to its Reputation.",
      },
      {
        type: BoonType.Income,
        text: "The gang may choose to roll two D6 after every battle.  On a role of 6 on either dice, the gang may recruit a single Juve from their House List for free.  If both dice come up as 6, then the gang may recruit a Ganger from their House List for free.",
      },
    ],
  },
  {
    id: 19,
    name: "Fighting Pits",
    gang: Gang.Goliath,
    graphic: TerritoryGraphic["Fighting Pits"],
    flavourText:
      "Violence is life, death and entertainment in the underhive, and some of the most feared fighters are made on the cinder floors of fighting pits under the eyes of baying crowds",
    boon: [
      {
        type: BoonType.Recruit,
        text: "Whilst it controls this Territory, the game may recruit two Hive Scum Hired Guns for free, including their equippment prior to every battle.",
      },
    ],
    enhancedBoon: [
      {
        type: BoonType.Reputation,
        text: "Whilst it controls this Territory, the gang add +2 to its Reputation.",
      },
    ],
  },
  {
    id: 20,
    name: "Smelting Works",
    gang: Gang.Goliath,
    graphic: TerritoryGraphic["Smelting Works"],
    flavourText:
      "Crags and seems of valuable slag can be found across the underhive, mute markers to the ancient industries that grew the spire.",
    boon: [
      {
        type: BoonType.Income,
        text: "The gang earns D6x5 credits from this Territory when collecting income.",
      },
    ],
    enhancedBoon: [
      {
        type: BoonType.Reputation,
        text: "Whilst it controls this Territory, the gang add +1 to its Reputation.",
      },
      {
        type: BoonType.Income,
        text: "The gang earns D6x5 credits from this Territory when collecting income. If the gang also controls a Slag Furnace, this is increase to D6x10.",
      },
    ],
  },

  // Orlock
  {
    id: 21,
    name: "Mine Workings",
    gang: Gang.Orlock,
    graphic: TerritoryGraphic["Mine Workings"],
    flavourText:
      "An open cast mine bored into a mineral mass can yield a fortune in corbonite gems, cognate ore, argent and emeralds, all waiting to be ripped from the ground.",
    boon: [
      {
        type: BoonType.Income,
        text: "The gang earns D6x10 credits from this Territory when collecting income. The gang may set Captured fighters to wok in the mines rather than selling them to the Guilders. For every Captive working the mine, roll an additional D6 to generate income. If the Territory change control, all of the Captives remain working the mine. A Captive sent to the mines may not be subsequently be Sold to the Guild. While a Captive is working the mine, their gang may attempt a Rescue Mission at any time.",
      },
    ],
    enhancedBoon: [
      {
        type: BoonType.Reputation,
        text: "Whilst it controls this Territory, the gang add +2 to its Reputation.",
      },
    ],
  },
  {
    id: 22,
    name: "Tunnels",
    gang: Gang.Orlock,
    graphic: TerritoryGraphic.Tunnels,
    flavourText:
      "The tunnels that run though the hive are primary ways that people movebetween locations. Those that control them canmove freely and attack where they wish.",
    boon: [
      {
        type: BoonType.Special,
        text: 'Whiles it controls this Territory, the gang may choose to have up to three figters deploy via tunnels ahead of any battle.  These fighters must be placed to one side. During the deployment phase, the players sets up two 2" wide tunnel enterence markers on any table edge on the ground surface of the battlefield. During the Priority phase of each turn, rol a D6. On a 4+, the group of fighters arrive on the battlefield. That turn they may be activated as a single group, and must move onto the battlefield from one of the tunnel entrance markers. The members of the group must all arrive though the same tunnel entrance. If the battle ends before the fighters arrive, they take no part in the battle.',
      },
    ],
    enhancedBoon: [
      {
        type: BoonType.Reputation,
        text: "Whilst it controls this Territory, the gang add +1 to its Reputation.",
      },
      {
        type: BoonType.Special,
        text: "An Orlock gang may choose to deploy up to six fighters via tunnels using the method detaied above. The fighters in each group must be specified before the battle.",
      },
    ],
  },
  {
    id: 23,
    name: "Toll Crossing",
    gang: Gang.Orlock,
    graphic: TerritoryGraphic["Toll Crossing"],
    flavourText:
      "The fastest way though the underhive often crosses chasms, sudge rivers or pass though bottlenecks. Gangs often set up at such points, extracting credits from those who wish to pass swiftly and safely on their way.",
    boon: [
      {
        type: BoonType.Income,
        text: "The gang earns D6x5 credits from this Territory when collecting income.",
      },
    ],
    enhancedBoon: [
      {
        type: BoonType.Special,
        text: "Whilst it controls this Territory, an Orlock gang has priority in the first round of any battle. Any gang in the campaign may pay the Orlock gang 20 credits to gain the same benefit in a single battle against another gang.",
      },
    ],
  },

  // Van Saar
  {
    id: 24,
    name: "Generatorium",
    gang: Gang["Van Saar"],
    graphic: TerritoryGraphic.Generatorium,
    flavourText:
      "Much of the light and power in the underhive relies on taps into energy conduits or barely functioning generators. Those that control the source of such power can let it flow... or cut it off at whim.",
    boon: [
      {
        type: BoonType.Special,
        text: "If their gang controls this Territory, a player may choose to stall the generators, temporarily cutting the power to the area in which the battle is taking place and plunging it into darkness.  They player may declare they will do this at the beginning of any Priority phase, before the roll for Priority.  For the remainder of the battle, the Pitch Black rules (see page 120) are in effect.  However, at the start of each End phase, there is a chance that the generators will automatically restart and the lights flood back.  At the start of each End phase, before making any Bottle tests, the players that controls this Territory rolls a D6. If the result is a 5 or more, the generators restart and the Pitch Back rules immediately cease to be in effect.  If the roll is a 1-4, the generators stay silent.",
      },
    ],
    enhancedBoon: [
      {
        type: BoonType.Reputation,
        text: "Whilst it controls this Territory, the gang add +1 to its Reputation.",
      },
    ],
  },
  {
    id: 25,
    name: "Archaeotech Device",
    gang: Gang["Van Saar"],
    graphic: TerritoryGraphic["Archaeotech Device"],
    flavourText:
      "These are relics from a long lost age,when the hive had not grown to the sky. They possess strange capabilities, wonderous power and danger in equal measure.",
    boon: [
      {
        type: BoonType.Special,
        text: "Any number of weapons owned by the gang may be given one of the following Traits for free: Baze, Rad-phage, Seismic or Shock.  All weapons must be given the same Trait and new weapons purchased later may also be given this Trait. These weapons also gain the Unstable Trait. If the Territory is lost, the weapons lose these additional Traits.",
      },
    ],
    enhancedBoon: [
      {
        type: BoonType.Reputation,
        text: "Whilst it controls this Territory, the gang add +2 to its Reputation.",
      },
      {
        type: BoonType.Special,
        text: "A Van Saar gang may be give any number of weapons it owns two of the following Traits for free: Baze, Rad-phage, Seismic or Shock.  All weapons must be given the same Trait and new weapons purchased later may also be given this Trait. These weapons also gain the Unstable Trait. If the Territory is lost, the weapons lose these additional Traits.",
      },
    ],
  },
  {
    id: 26,
    name: "Tech Bazaar",
    gang: Gang["Van Saar"],
    graphic: TerritoryGraphic["Tech Bazaar"],
    flavourText:
      "An underhive tech bazaar buzzes with the calls of bullet merchants echoing above the haggling tech-prospectors, while agents ofthe Gun-guild watch and take their due.",
    boon: [
      {
        type: BoonType.Income,
        text: "The gang earns D6x10 credits from this Territory when collecting income.",
      },
      {
        type: BoonType.Equipment,
        text: "Select a Leader or Champion to make a Haggle post-battle action. Roll 2D6. The gang may immediately choose one item from the Rare Trade chart with a Rare value equal to the result of the dice roll and add it to their Stash for half its usual value,  rounded down. If the roll is lower than 7, pick a Common weapon or piece of equippment to add to the gang's Stash for half of its usual value, rounded down. If the role is 3 or lower, then the fighter proves to be very poor at haggling and no equipment is gained. If the fighter selected has Exotic Furs, add +1 to the result of the 2D6 dice roll.",
      },
    ],
    enhancedBoon: [
      {
        type: BoonType.Reputation,
        text: "Whilst it controls this Territory, the gang add +1 to its Reputation.",
      },
      {
        type: BoonType.Income,
        text: "The gang earns D6x10 credits from this Territory when collecting income. If the gang also controls an Archaeotech Device, this is increased to 2D6x10.",
      },
    ],
  },
];

export default DefaultTerritories;
