import { Alert, Button, Modal } from "react-bootstrap";

export interface EndCampaignProps {
  show: boolean;
  onCancel: () => void;
  onSubmit: () => void;
}

const EndCampaign = ({ show, onCancel, onSubmit }: EndCampaignProps) => {
  return (
    <Modal show={show} onHide={onCancel} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Start New Campaign Week</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert variant="warning">
          Are you sure you wish end the Campaign?
          <br />
          <br />
          No more battles can be fought and no more results cannot be altered at
          all. This cannot be undone!
        </Alert>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="danger" onClick={onSubmit}>
          End Campaign
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EndCampaign;
