import { Form, FormControlProps } from "react-bootstrap";

export interface FormInputProps extends FormControlProps {
  name: string;
  label: string;
  as?: "input" | "textarea";
  rows?: number;
  min?: string;
  max?: string;
  step?: string;
  autocomplete?: string;
}

const FormInput = ({ name, label, as = "input", ...props }: FormInputProps) => {
  return (
    <Form.FloatingLabel controlId={name} label={label} className="mb-3">
      <Form.Control as={as} placeholder={label} {...props} />
    </Form.FloatingLabel>
  );
};

export default FormInput;
