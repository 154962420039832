import { Table } from "react-bootstrap";
import { enumEquals } from "../services/helpers";
import { CampaignEvent } from "../types";
import { CampaignPhase } from "../types/campaignPhase";

export interface CampaignEventProps {
  event: CampaignEvent;
}

const CampaignEventDisplay = ({ event }: CampaignEventProps) => {
  const displayPhase = (phase: number) => {
    switch (phase) {
      case 0:
        return "Occupation Phase";
      case 1:
        return "Down Time";
      case 2:
        return "Take Over Phase";
    }
  };

  const displayChallenge = () => {
    if (!event.battle) return;

    let title = "";

    if (event.battle.territoryId) {
      const territory = event.territoryOwnership[event.battle.territoryId];

      if (
        enumEquals(CampaignPhase, event.week.phase, CampaignPhase.TakeOver) &&
        territory.controlledByUserId
      ) {
        const challengers = event.players
          .filter((p) => p?.user.userId !== territory.controlledByUserId)
          .reduce(
            (acc, p) =>
              acc === "" ? p?.gangName ?? "" : `${acc} & ${p?.gangName}`,
            "",
          );
        return `${challengers} challange ${displayGang(
          territory.controlledByUserId,
        )} for control of ${territory.name}`;
      } else {
        title = `${event.players[0]?.gangName} challenge ${event.players[1]?.gangName}`;
        if (event.players.length > 2) {
          for (let i = 2; i < event.players.length; i++)
            title = `${title} & ${event.players[i]?.gangName}`;
        }
        return `${title} for control of the ${territory.name}`;
      }
    } else {
      title = `${event.players[0]?.gangName} challenge ${event.players[1]?.gangName}`;
      if (event.players.length > 2) {
        for (let i = 2; i < event.players.length; i++)
          title = `${title} & ${event.players[i]?.gangName}`;
      }
      return title;
    }
  };

  const displayWinner = () => {
    if (!event.battle) return;

    if (!event.battle.winningPlayer) return "Draw";

    return `${displayGang(event.battle?.winningPlayer)} is Victorious`;
  };

  const displayGang = (userId: string) => {
    return event.players.find((p) => p.user.userId === userId)?.gangName;
  };

  return (
    <>
      {!event.battle && (
        <>
          <h4>
            Start of week {event.week.weekNumber}:
            {displayPhase(event.week.phase)}
          </h4>
          {event.week.weekNumber > 1 && (
            <Table striped bordered>
              <thead>
                <tr>
                  <th>Player</th>
                  <th
                    style={{
                      textAlign: "right",
                      verticalAlign: "middle",
                      writingMode: "vertical-rl",
                    }}>
                    Battles
                  </th>
                  <th
                    style={{
                      textAlign: "right",
                      verticalAlign: "middle",
                      writingMode: "vertical-rl",
                    }}>
                    Territories
                  </th>
                  <th
                    style={{
                      textAlign: "right",
                      verticalAlign: "middle",
                      writingMode: "vertical-rl",
                    }}>
                    Fighter OoA
                  </th>
                  <th
                    style={{
                      textAlign: "right",
                      verticalAlign: "middle",
                      writingMode: "vertical-rl",
                    }}>
                    Rating
                  </th>
                  <th
                    style={{
                      textAlign: "right",
                      verticalAlign: "middle",
                      writingMode: "vertical-rl",
                    }}>
                    Wealth
                  </th>
                  <th
                    style={{
                      textAlign: "right",
                      verticalAlign: "middle",
                      writingMode: "vertical-rl",
                    }}>
                    Reputation
                  </th>
                </tr>
              </thead>
              <tbody>
                {event.leaderBoard
                  .sort((a, b) => b.rating - a.rating)
                  .map((s) => (
                    <tr key={s.userId}>
                      <td>{displayGang(s.userId)}</td>
                      <td style={{ textAlign: "center" }}>{s.battles}</td>
                      <td style={{ textAlign: "center" }}>{s.territories}</td>
                      <td style={{ textAlign: "center" }}>{s.outOfActions}</td>
                      <td style={{ textAlign: "center" }}>{s.rating}</td>
                      <td style={{ textAlign: "center" }}>{s.wealth}</td>
                      <td style={{ textAlign: "center" }}>{s.reputation}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          )}
        </>
      )}
      {event.battle && (
        <>
          <h4>{displayChallenge()}</h4>

          {event.battle.playerScores && (
            <>
              <h5 className="text-center text-info">{displayWinner()}</h5>
              <Table striped bordered>
                <thead>
                  <tr>
                    <th>Player</th>
                    <th
                      style={{
                        textAlign: "right",
                        verticalAlign: "middle",
                        writingMode: "vertical-rl",
                      }}>
                      Fighter OoA
                    </th>
                    <th
                      style={{
                        textAlign: "right",
                        verticalAlign: "middle",
                        writingMode: "vertical-rl",
                      }}>
                      Rating
                    </th>
                    <th
                      style={{
                        textAlign: "right",
                        verticalAlign: "middle",
                        writingMode: "vertical-rl",
                      }}>
                      Wealth
                    </th>
                    <th
                      style={{
                        textAlign: "right",
                        verticalAlign: "middle",
                        writingMode: "vertical-rl",
                      }}>
                      Reputation
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {event.battle.playerScores.map((s) => (
                    <tr key={s.userId}>
                      <td>{displayGang(s.userId)}</td>
                      <td style={{ textAlign: "center" }}>{s.outOfActions}</td>
                      <td style={{ textAlign: "center" }}>{s.rating}</td>
                      <td style={{ textAlign: "center" }}>{s.wealth}</td>
                      <td style={{ textAlign: "center" }}>{s.reputation}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          )}
        </>
      )}
    </>
  );
};

export default CampaignEventDisplay;
