import styled from "styled-components";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.5;
  z-index: 100;
`;

const Spinner = styled.div`
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 0px auto;
  width: 80px;
  height: 80px;
  z-index: 101;

  &:after {
    content: " ";
    display: block;
    border-radius: 50%;
    width: 0;
    height: 0;
    margin: 8px;
    box-sizing: border-box;
    border: 32px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: animate 1.2s infinite;
  }
  @keyframes animate {
    0% {
      transform: rotate(0);
      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
      transform: rotate(900deg);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
      transform: rotate(1800deg);
    }
  }
`;

const LoadingSpinner = () => {
  return (
    <>
      <Overlay />
      <Spinner />
    </>
  );
};

export default LoadingSpinner;
