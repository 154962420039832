import { useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";

import { useApiContext } from "../api/apiContext";
import { useAuthContext } from "../auth/authContext";
import { useNavigate } from "react-router-dom";

import LoadingSpinner from "../components/loadingSpinner";

const schema = yup.object({
  email: yup
    .string()
    .email("Must be a valid email")
    .required("Email is required"),
  name: yup.string().required("Name is required"),
  changePassword: yup.bool(),
  password: yup.string().when("changePassword", {
    is: true,
    then: (schema) =>
      schema
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
          "Password not complex enough",
        )
        .required("Password is required"),
  }),
  password2: yup.string().when("changePassword", {
    is: true,
    then: (schema) =>
      schema
        .oneOf([yup.ref("password"), ""], "Passwords must match")
        .required("Please repeat your password"),
  }),
});

const UpdateAccount = () => {
  const apiContext = useApiContext();
  const authContext = useAuthContext();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const handleSubmit = (values: {
    email: string;
    name: string;
    changePassword: boolean;
    password: string;
    password2: string;
  }) => {
    setLoading(true);
    apiContext
      .updateAccount(
        values.email,
        values.name,
        values.changePassword ? values.password : undefined,
      )
      .then(() => navigate("/"))
      .finally(() => setLoading(false));
  };

  return (
    <>
      {loading && <LoadingSpinner />}
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        initialValues={{
          email: authContext.currentUser?.email ?? "",
          name: authContext.currentUser?.name ?? "",
          changePassword: false,
          password: "",
          password2: "",
        }}>
        {({
          handleChange,
          values,
          errors,
          handleBlur,
          touched,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit} className="flex-fill mx-3">
            <Card>
              <Card.Header>Update Account</Card.Header>
              <Card.Body>
                <Form.Group className="position-relative mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Email"
                    onChange={handleChange}
                    value={values.email}
                    isInvalid={touched.email && !!errors.email}
                    onBlur={handleBlur}
                    name="email"
                  />
                  <Form.Control.Feedback type="invalid" tooltip={true}>
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="position-relative mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Name"
                    onChange={handleChange}
                    value={values.name}
                    isInvalid={touched.name && !!errors.name}
                    onBlur={handleBlur}
                    name="name"
                  />
                  <Form.Control.Feedback type="invalid" tooltip={true}>
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Check
                    type="checkbox"
                    label="Change Password"
                    onChange={handleChange}
                    checked={values.changePassword}
                    onBlur={handleBlur}
                    name="changePassword"
                  />
                </Form.Group>
                {values.changePassword && (
                  <>
                    <Form.Group className="position-relative mb-3">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        onChange={handleChange}
                        value={values.password}
                        isInvalid={touched.password && !!errors.password}
                        onBlur={handleBlur}
                        name="password"
                      />
                      <Form.Control.Feedback type="invalid" tooltip={true}>
                        {errors.password}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="position-relative mb-3">
                      <Form.Label>Retype Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Retype password"
                        onChange={handleChange}
                        value={values.password2}
                        isInvalid={touched.password2 && !!errors.password2}
                        onBlur={handleBlur}
                        name="password2"
                      />
                      <Form.Control.Feedback type="invalid" tooltip={true}>
                        {errors.password2}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </>
                )}
              </Card.Body>
              <Card.Footer className="text-end">
                <Button variant="primary" type="submit" disabled={loading}>
                  Update
                </Button>
              </Card.Footer>
            </Card>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default UpdateAccount;
