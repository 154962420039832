import { Mesh } from "three";
// @ts-ignore
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";

import { Territory } from "../types";
import { TerritoryGraphic } from "../types/territoryGraphic";

import { getEnumKeys } from "./helpers";
import ThreeService from "./threeService";

class ThreeFactory {
  public static async create(
    canvas: HTMLCanvasElement,
    territories: Territory[],
  ): Promise<ThreeService> {
    return this.loadAssets(territories).then((assets) => {
      if (!canvas) Promise.reject();
      return new ThreeService(canvas, assets);
    });
  }

  private static async loadAssets(
    territories: Territory[],
  ): Promise<{ name: string; object: Mesh }[]> {
    const objectNames = [
      { name: "tileEdge", fileName: "tileEdge" },
      { name: "domeBase", fileName: "domeBase" },
      { name: "dome", fileName: "dome" },
      { name: "pipes1", fileName: "pipes1" },
      { name: "pipes2", fileName: "pipes2" },
      { name: "pipes3", fileName: "pipes3" },
      { name: "pipes4", fileName: "pipes4" },
      { name: "Settlement", fileName: "settlement" },
    ];
    const objects: { name: string; object: Mesh }[] = [];

    // Get common assets that are always needed
    await Promise.all(
      objectNames.map(async ({ name, fileName }) => {
        objects.push({
          name: name,
          object: await this.loadGltfObject(`/3dObjects/${fileName}.glb`),
        });
      }),
    );

    // Get territory assets only needed if the asset is used in selected territories
    await Promise.all(
      getEnumKeys(TerritoryGraphic)
        .filter((g) => g !== "Settlement")
        .map(async (graphic) => {
          const graphicUsed = territories.some(
            (t) =>
              t.graphic !== undefined &&
              TerritoryGraphic[t.graphic] === graphic,
          );
          if (!graphicUsed) return;
          const fileName = (graphic as string).replaceAll(" ", "");
          objects.push({
            name: graphic,
            object: await this.loadGltfObject(
              `/3dObjects/${fileName.charAt(0).toLowerCase()}${fileName.slice(
                1,
              )}.glb`,
            ),
          });
        }),
    );

    return objects;
  }

  private static async loadGltfObject(url: string): Promise<Mesh> {
    const loader = new GLTFLoader();
    return new Promise((resolve, reject) => {
      loader.load(
        url,
        (object: any) => {
          return resolve(object.scene.children[0] as Mesh);
        },
        null,
        (error: any) => reject(error),
      );
    });
  }
}

export default ThreeFactory;
