import { useState, FocusEvent, KeyboardEvent, useRef } from "react";
import { Form, FormControlProps } from "react-bootstrap";
import styled from "styled-components";
import debounce from "lodash.debounce";

const ItemSelect = styled.div`
  position: absolute;
  background-color: white;
  top: 100%;
  z-index: 100;
  left: 0;
  right: 0;
  border: 1px solid #ccc;
  border-radius: 5px;

  ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;

    li {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
      padding-right: 1rem !important;
      padding-left: 1rem !important;
      cursor: default;
    }

    li:hover,
    li.current {
      background-color: #1967d2;
      color: white;
    }
  }
`;

export interface FormLookupProps extends FormControlProps {
  label: string;
  lookupThreshold?: number;
  debounceTime?: number;
  onLookup: (value: string) => Promise<any[]>;
  itemKey: string;
  itemLabel: string;
  onSelect: (item: any) => void;
}

const FormLookup = ({
  label,
  lookupThreshold = 4,
  debounceTime = 200,
  onLookup,
  itemKey,
  itemLabel,
  onSelect,
  ...props
}: FormLookupProps) => {
  const [value, setValue] = useState<string>("");
  const [options, setOptions] = useState<any[]>([]);
  const [highlightedOption, setHighlightedOption] = useState<
    number | undefined
  >(undefined);

  const doLookup = useRef(
    debounce((value) => {
      if (value.length < 5) return;
      onLookup(value).then((items) => {
        setOptions(items);
        setHighlightedOption(undefined);
      });
    }, debounceTime),
  );

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    setOptions([]);
    setHighlightedOption(undefined);
    setValue("");
  };

  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "ArrowDown" && options.length) {
      setHighlightedOption(
        !highlightedOption
          ? 0
          : highlightedOption + 1 > options.length
          ? options.length - 1
          : highlightedOption + 1,
      );
    } else if (e.key === "ArrowUp" && options.length) {
      setHighlightedOption(
        !highlightedOption
          ? undefined
          : highlightedOption - 1 < 0
          ? 0
          : highlightedOption - 1,
      );
    } else if (
      e.key === "Enter" &&
      options.length &&
      highlightedOption !== undefined
    ) {
      selectOption(options[highlightedOption]);
    } else {
      doLookup.current(value);
    }
  };

  const selectOption = (option: any) => {
    setValue(option[itemLabel]);
    onSelect(option);
    setOptions([]);
    setHighlightedOption(undefined);
    setValue("");
  };

  return (
    <div className="position-relative">
      <Form.FloatingLabel label={label} className="mb-3">
        <Form.Control
          {...props}
          as="input"
          placeholder={label}
          onBlur={handleBlur}
          onKeyUp={handleKeyUp}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          autoComplete="off"
        />
      </Form.FloatingLabel>
      {options.length > 0 && (
        <ItemSelect>
          <ul>
            {options.map((option, index) => (
              <li
                key={option[itemKey]}
                className={highlightedOption === index ? "current" : ""}
                onMouseDown={() => selectOption(option)}>
                {option[itemLabel]}
              </li>
            ))}
          </ul>
        </ItemSelect>
      )}
    </div>
  );
};

export default FormLookup;
