import { Card } from "react-bootstrap";

const Terms = () => {
  return (
    <>
      <Card className="w-75 mx-auto">
        <Card.Header>Terms and Conditions of Use</Card.Header>
        <Card.Body style={{ fontSize: "0.8rem" }}>
          <p>
            If you continue to browse and use this website, you are agreeing to
            comply with and be bound by the following terms and conditions of
            use, which together with our privacy policy govern our relationship
            with you in relation to this website. If you disagree with any part
            of these terms and conditions, please do not use our website.
          </p>
          <p>
            The term 'us' or 'we' refers to the owner of the website. The term
            'you' refers to the user or viewer of our website.
          </p>
          <p>
            The use of this website is subject to the following terms of use:
          </p>
          <ul>
            <li>
              The content of the pages of this website is for your general
              information and use only. It is subject to change without notice.
            </li>
            <li>
              This website uses cookies to monitor browsing preferences. If you
              do allow cookies to be used, the following personal information
              may be stored by us for use by third parties.
            </li>
            <li>
              Neither we nor any third parties provide any warranty or guarantee
              as to the accuracy, timeliness, performance, completeness or
              suitability of the information and materials found or offered on
              this website for any particular purpose. You acknowledge that such
              information and materials may contain inaccuracies or errors and
              we expressly exclude liability for any such inaccuracies or errors
              to the fullest extent permitted by law.
            </li>
            <li>
              Your use of any information or materials on this website is
              entirely at your own risk, for which we shall not be liable. It
              shall be your own responsibility to ensure that any products,
              services or information available through this website meet your
              specific requirements.
            </li>
            <li>
              This website contains material which is owned by or licensed to
              us. This material includes, but is not limited to, the design,
              layout, look, appearance and graphics, 3d models. Reproduction is
              prohibited other than in accordance with the copyright notice,
              which forms part of these terms and conditions.
            </li>
            <li>
              All trade marks reproduced in this website which are not the
              property of, or licensed to, the operator are acknowledged on the
              website.
            </li>
            <li>
              The trademark &quote;Necromunda&quote; is the property of Games
              Workshop Limited. This site offers no challange to any of Games
              Workshops trademarks or interlectual property.
            </li>
            <li>
              Unauthorised use of this website may give rise to a claim for
              damages and/or be a criminal offence.
            </li>
            <li>
              From time to time this website may also include links to other
              websites. These links are provided for your convenience to provide
              further information. They do not signify that we endorse the
              website(s). We have no responsibility for the content of the
              linked website(s).
            </li>
            <li>
              Your use of this website and any dispute arising out of such use
              of the website is subject to the laws of England, Northern
              Ireland, Scotland and Wales.
            </li>
          </ul>
        </Card.Body>
      </Card>
    </>
  );
};

export default Terms;
