import { Button, Form, Modal } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";

import FormikFormInput from "./formikFormInput";
import FormikFormSelect from "./formikFormSelect";

import { TerritoryLocation } from "../types";

import { useSetupContext } from "../pages/setup/setupContext";

export interface TerritoryLocationFormProps {
  territory: TerritoryLocation | undefined;
  onCancel: () => void;
  onSubmit: (value: TerritoryLocation) => void;
}

const validationSchema = Yup.object({
  name: Yup.string().required(),
  flavourText: Yup.string().optional(),
  controlledByUserId: Yup.string().optional(),
});

const TerritoryLocationForm = ({
  territory,
  onCancel,
  onSubmit,
}: TerritoryLocationFormProps) => {
  const { value: wizardValue } = useSetupContext();

  const playerOptions = [
    { value: -1, label: "Uncontrolled" },
    ...wizardValue.players.map((p) => ({
      value: p.user.userId,
      label: p.gangName,
    })),
  ];

  return (
    <>
      {territory && (
        <Modal show={true} onHide={onCancel} backdrop="static" scrollable>
          <Formik
            initialValues={territory}
            validationSchema={validationSchema}
            onSubmit={onSubmit}>
            {({ handleSubmit, isValid }) => (
              <Form
                noValidate
                onSubmit={handleSubmit}
                className="modal-content">
                <Modal.Header closeButton>
                  <Modal.Title>Edit Territory</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <FormikFormInput
                    name="name"
                    type="text"
                    label="Name"
                    autoComplete="off"
                  />
                  <FormikFormInput
                    name="flavourText"
                    type="text"
                    label="Favour Text"
                    autoComplete="off"
                    as="textarea"
                    rows={3}
                  />
                  <FormikFormSelect
                    disabled={territory.id < 1}
                    name="controlledByUserId"
                    label="Controlled By"
                    options={playerOptions}
                  />
                </Modal.Body>
                <Modal.Footer>
                  {isValid}
                  <Button variant="secondary" onClick={onCancel}>
                    Cancel
                  </Button>
                  <Button variant="primary" disabled={!isValid} type="submit">
                    Save
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
    </>
  );
};

export default TerritoryLocationForm;
