import { createContext, useContext } from "react";
import { Dome, Player, Territory, TerritoryLocation } from "../../types";

export interface Wizard {
  sandboxMode: boolean;
  currentStep: number;
  name: string;
  flavourText: string;
  players: Player[];
  territoriesDeck: Territory[];
  selectedTerritories: Territory[];
  campaignTerritories: TerritoryLocation[];
  domes: Dome[];
  isValid: boolean[];
}

export interface SetupContextType {
  value: Wizard;
  update: (value: Wizard) => void;
}

const SetupContext = createContext<SetupContextType | undefined>(undefined);

const useSetupContext = () => {
  // get the context
  const context = useContext(SetupContext);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error("useSetupContext was used outside of its Provider");
  }

  return context;
};

export { useSetupContext, SetupContext };
