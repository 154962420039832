import { FieldHookConfig, useField } from "formik";

import FormSelect, { FormSelectProps } from "./formSelect";

const FormikFormSelect = ({
  ...props
}: FormSelectProps & FieldHookConfig<string | number | undefined>) => {
  const [{ ...field }, { error, touched }, { setValue }] = useField(props);

  return (
    <FormSelect
      {...field}
      onChange={(e) =>
        setValue(
          e.target.value === ""
            ? undefined
            : Number.isNaN(+e.target.value)
            ? e.target.value
            : +e.target.value,
        )
      }
      isValid={touched && !error}
      isInvalid={touched && error !== undefined}
      {...props}
    />
  );
};

export default FormikFormSelect;
