import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Player, PlayerCampaignScore } from "../types";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export interface TriumphProps {
  title: string;
  icon: IconProp;
  tooltip: string;
  sortProp: keyof PlayerCampaignScore;
  players: Player[];
  scores: PlayerCampaignScore[];
  resultCallback: (result: number) => string;
}

const Triumph = ({
  title,
  icon,
  tooltip,
  sortProp,
  players,
  scores,
  resultCallback,
}: TriumphProps) => {
  const displayGangAndPlayer = (userId: string) => {
    const player = players.find((p) => p.user.userId === userId);
    if (!player) return "";
    return `${player.gangName} (${player.user.name})`;
  };

  const orderedScores = scores.sort(
    (a, b) =>
      (b[sortProp] as number) - (a[sortProp] as number) || b.rating - a.rating,
  );

  const winningScore =
    orderedScores[0][sortProp] === orderedScores[1][sortProp] &&
    orderedScores[0].rating === orderedScores[1].rating
      ? null
      : orderedScores[0];

  return (
    <>
      <dt className="text-info" title={tooltip}>
        <FontAwesomeIcon icon={icon} /> {title}
      </dt>
      <dd>
        {!winningScore && <>No overall winner</>}
        {winningScore && (
          <>
            {displayGangAndPlayer(winningScore.userId)}{" "}
            {resultCallback(winningScore[sortProp] as number)}
          </>
        )}
      </dd>
    </>
  );
};

export default Triumph;
