import { Form, FormSelectProps as BsFormSelectProps } from "react-bootstrap";

export interface FormSelectProps extends BsFormSelectProps {
  name: string;
  label: string;
  options: { value: string | number; label: string }[];
  nullOptionLabel?: string;
  nullOptionDisabled?: boolean;
}

const FormSelect = ({
  name,
  label,
  options,
  nullOptionLabel,
  nullOptionDisabled,
  ...props
}: FormSelectProps) => {
  return (
    <Form.FloatingLabel controlId={name} label={label} className="mb-3">
      <Form.Select {...props}>
        <option disabled={nullOptionDisabled ?? true} value="">
          {nullOptionLabel ?? label}
        </option>
        {options.map(({ value, label }, index) => (
          <option key={index} value={value}>
            {label}
          </option>
        ))}
      </Form.Select>
    </Form.FloatingLabel>
  );
};

export default FormSelect;
