import { useEffect, useRef, useState } from "react";
import { Card, Form, Row } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";

import { useSetupContext } from "./setupContext";

import { FormikFormInput } from "../../components";

const validationSchema = Yup.object({
  name: Yup.string().required("Please enter a name for the gang"),
  flavourText: Yup.string(),
});

const Name = () => {
  const { value: wizardValue, update: updateWizard } = useSetupContext();

  const handleSubmit = (values: { name: string; flavourText: string }) => {
    wizardValue.isValid[0] = true;
    updateWizard({
      ...wizardValue,
      name: values.name,
      flavourText: values.flavourText,
    });
  };

  return (
    <>
      <Card.Title>Name</Card.Title>
      <Card.Text className="text-body-tertiary fst-italic fs-5">
        Welcome to the Campaign Creation Wizard. This tool will take you
        step-by-step though creating your Dominion campaign.
      </Card.Text>
      <Card.Text className="text-body-tertiary fst-italic fs-5">
        On this page, please give your campaign a name and optionally write some
        flavour text to engage your players.
      </Card.Text>
      <Row>
        <Formik
          initialValues={{
            name: wizardValue.name,
            flavourText: wizardValue.flavourText,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}>
          {({ isValid, handleSubmit }) => (
            <Form noValidate>
              <FormikFormInput
                name="name"
                type="text"
                label="Campaign Name"
                onChange={() => {
                  if (isValid) handleSubmit();
                }}
              />
              <FormikFormInput
                name="flavourText"
                type="text"
                label="Flavour Text"
                as="textarea"
                rows={5}
                onChange={() => {
                  if (isValid) handleSubmit();
                }}
              />
            </Form>
          )}
        </Formik>
      </Row>
    </>
  );
};

export default Name;
