import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";

import LoadingSpinner from "../components/loadingSpinner";

import { useApiContext } from "../api/apiContext";

const schema = yup.object({
  email: yup
    .string()
    .email("Must be a valid email")
    .required("Email is required"),
});

const ForgotPassword = () => {
  const apiContext = useApiContext();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const handleSubmit = (values: { email: string }) => {
    setLoading(true);
    apiContext
      .forgottenPassword(values.email)
      .then(() => navigate("/login"))
      .finally(() => setLoading(false));
  };

  return (
    <>
      {loading && <LoadingSpinner />}
      <div className="d-flex align-items-stretch">
        <Formik
          validationSchema={schema}
          onSubmit={handleSubmit}
          initialValues={{ email: "" }}>
          {({
            handleChange,
            values,
            errors,
            handleBlur,
            touched,
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit} className="flex-fill mx-3">
              <Card className="h-100">
                <Card.Header>Login</Card.Header>
                <Card.Body>
                  <Form.Group className="position-relative mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Email"
                      onChange={handleChange}
                      value={values.email}
                      isInvalid={touched.email && !!errors.email}
                      onBlur={handleBlur}
                      name="email"
                    />
                    <Form.Control.Feedback type="invalid" tooltip={true}>
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Card.Body>
                <Card.Footer className="text-end">
                  <Button variant="primary" type="submit">
                    Send Reset Email
                  </Button>
                </Card.Footer>
              </Card>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default ForgotPassword;
