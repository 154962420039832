import { createContext, useContext } from "react";

import { AlertMessage } from "../types/alertMessage";

export interface AlertContextType {
  showAlert: (alert: AlertMessage) => void;
}

const AlertContext = createContext<AlertContextType | undefined>(undefined);

const useAlertContext = () => {
  // get the context
  const context = useContext(AlertContext);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error("useAlertContext was used outside of its Provider");
  }

  return context;
};

export { AlertContext, useAlertContext };
