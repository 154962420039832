import { useEffect, useState } from "react";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";

import FormikEnumFormSelect from "./formikEnumFormSelect";

import { CampaignWeekReqest } from "../types/requests";
import { CampaignPhase } from "../types/campaignPhase";

import { enumEquals, getEnumValues } from "../services/helpers";

export interface AddBattleFormProps {
  show: boolean;
  currentPhase: CampaignPhase;
  allTerritoriesClaimed: boolean;
  onCancel: () => void;
  onSubmit: (value: CampaignWeekReqest) => void;
}

const NewWeekForm = ({
  show,
  currentPhase,
  allTerritoriesClaimed,
  onCancel,
  onSubmit,
}: AddBattleFormProps) => {
  const [campaignWeek, setCampaignWeek] = useState<
    CampaignWeekReqest | undefined
  >();

  useEffect(() => {
    setCampaignWeek({
      phase: currentPhase,
    });
  }, [show]);

  var acceptedValues = allTerritoriesClaimed
    ? getEnumValues(CampaignPhase).filter(
        (v) =>
          !enumEquals(
            CampaignPhase,
            v,
            CampaignPhase[CampaignPhase.Occupation],
          ),
      )
    : getEnumValues(CampaignPhase);
  const validationSchema = Yup.object({
    phase: Yup.mixed().oneOf(acceptedValues).required(),
  });

  return (
    <>
      {show && campaignWeek && (
        <Modal show={true} onHide={onCancel} backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>Start New Campaign Week</Modal.Title>
          </Modal.Header>

          <Formik
            initialValues={campaignWeek}
            validationSchema={validationSchema}
            onSubmit={onSubmit}>
            {({ handleSubmit, isValid }) => (
              <Form
                noValidate
                onSubmit={handleSubmit}
                className="modal-content">
                <Modal.Body>
                  <Alert variant="warning">
                    Are you sure you wish start a new Campaign Week?
                    <br />
                    <br />
                    No more battles can be fought in this week and results
                    cannot be altered if you proceed.
                  </Alert>

                  {allTerritoriesClaimed && (
                    <Alert variant="info">
                      All territories are now claimed. You cannot start another
                      week in the Occupation Phase.
                    </Alert>
                  )}

                  <FormikEnumFormSelect
                    name="phase"
                    enumType={CampaignPhase}
                    label="Phase"
                  />
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={onCancel}>
                    Cancel
                  </Button>
                  <Button variant="primary" disabled={!isValid} type="submit">
                    Save
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
    </>
  );
};

export default NewWeekForm;
