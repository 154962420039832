import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";

import AlertProvider from "./alert/alertProvider";
import ApiProvider from "./api/apiProvider";
import AuthProvider from "./auth/authProvider";

import CampaignList from "./pages/campaignList";
import CampaignView from "./pages/campaignView";
import ForgotPassword from "./pages/forgotPassword";
import Home from "./pages/home";
import Layout from "./pages/layout";
import Login from "./pages/login";
import Logout from "./pages/logout";
import NoPage from "./pages/noPage";
import ResetPassword from "./pages/resetPassword";
import Setup from "./pages/setup/setup";
import Terms from "./pages/terms";
import UpdateAccount from "./pages/updateAccount";

import "bootstrap/dist/css/bootstrap.min.css";
import "./bootstrap.min.css";
import "./index.scss";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <AlertProvider>
    <AuthProvider>
      <ApiProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="login" element={<Login />} />
              <Route path="forgotpassword" element={<ForgotPassword />} />
              <Route path="resetpassword" element={<ResetPassword />} />
              <Route path="campaigns" element={<CampaignList />} />
              <Route path="campaigns/:campaignId" element={<CampaignView />} />
              <Route path="setup" element={<Setup />} />
              <Route path="logout" element={<Logout />} />
              <Route path="terms" element={<Terms />} />
              <Route path="updateAccount" element={<UpdateAccount />} />
              <Route path="*" element={<NoPage />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ApiProvider>
    </AuthProvider>
  </AlertProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
