import { ChangeEvent, useState } from "react";
import { Button, Card, Col, ListGroup, Row } from "react-bootstrap";

import {
  FormInput,
  TerritoryDetails,
  TerritoryForm,
  TerritoryHeader,
} from "../../components";
import { enumEquals, groupBy } from "../../services/helpers";
import { Territory } from "../../types";
import { GangOrOther } from "../../types/gang";

import { useSetupContext } from "./setupContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { TerritoryGraphic } from "../../types/territoryGraphic";

const Territories = () => {
  const { value: wizardValue, update: updateWizard } = useSetupContext();
  const [numberOfTerritories, setNumberOfTerritories] = useState(
    wizardValue.players.length * 3,
  );
  const [expandedTerritory, setExpandedTerritory] = useState<
    Territory | undefined
  >();
  const [editTerritory, setEditTerritory] = useState<Territory | undefined>(
    undefined,
  );
  const maxTerritories = 24;

  const handleAddTerritory = () => {
    const newTerritory: Territory = {
      id: wizardValue.territoriesDeck.length + 1,
      name: "",
      graphic: TerritoryGraphic["Old Ruins"],
      flavourText: "",
      gang: undefined,
      boon: [],
      enhancedBoon: undefined,
      isCustom: true,
    };
    setEditTerritory(newTerritory);
  };

  const handleNumberOfTerritoriesChange = (
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    const numberOfTerritories = Number(e.target.value);
    setNumberOfTerritories(numberOfTerritories);
    if (wizardValue.selectedTerritories.length > numberOfTerritories) {
      updateWizard({
        ...wizardValue,
        selectedTerritories: wizardValue.selectedTerritories.slice(
          0,
          numberOfTerritories,
        ),
      });
    }
  };

  const handleGenerate = () => {
    const groups = groupBy(
      wizardValue.territoriesDeck,
      (t) => t.gang ?? "Common",
    );

    const selectedTerritories = wizardValue.players
      .map((player) =>
        !player.gang.houseGang ||
        enumEquals(GangOrOther, player.gang.houseGang, GangOrOther.Other)
          ? player.gang.territoryGang
            ? player.gang.territoryGang
            : player.gang.customGangType ?? ""
          : player.gang.houseGang,
      )
      .filter((value, index, array) => array.indexOf(value) === index)
      .map((gang) => {
        const gangTerritories = groups[gang];
        if (!gangTerritories) return undefined;
        const random = Math.floor(Math.random() * gangTerritories.length);
        return gangTerritories[random];
      })
      .filter((territory) => territory !== undefined) as Territory[];

    while (selectedTerritories.length < numberOfTerritories) {
      const remainingTerritories = wizardValue.territoriesDeck.filter(
        (t1) => !selectedTerritories.some((t2) => t1 === t2),
      );
      if (remainingTerritories.length === 0) break;
      const random = Math.floor(Math.random() * remainingTerritories.length);
      selectedTerritories.push(remainingTerritories[random]);
    }

    wizardValue.isValid[2] = selectedTerritories.length === numberOfTerritories;
    updateWizard({
      ...wizardValue,
      selectedTerritories: selectedTerritories,
    });
  };

  const handleClear = () => {
    wizardValue.isValid[1] = false;
    updateWizard({
      ...wizardValue,
      selectedTerritories: [],
    });
  };

  const toggeTerritory = (territory: Territory) => {
    setExpandedTerritory(
      expandedTerritory === territory ? undefined : territory,
    );
  };

  const handleTerritoryAdd = (territory: Territory) => {
    const territories = [...wizardValue.selectedTerritories, territory];
    wizardValue.isValid[2] = territories.length === numberOfTerritories;
    updateWizard({
      ...wizardValue,
      selectedTerritories: territories,
      campaignTerritories: [],
    });
  };

  const handleTerritoryRemove = (territory: Territory) => {
    const territories = wizardValue.selectedTerritories.filter(
      (t) => t !== territory,
    );
    wizardValue.isValid[1] = false;
    updateWizard({
      ...wizardValue,
      selectedTerritories: territories,
      campaignTerritories: [],
    });
  };

  const handleSaveTerritory = (territory: Territory) => {
    setEditTerritory(undefined);
    updateWizard({
      ...wizardValue,
      territoriesDeck: [
        ...wizardValue.territoriesDeck.filter((t) => t.id !== territory.id),
        territory,
      ],
    });
  };

  const handleCustomTerritoryDelete = (territory: Territory) => {
    updateWizard({
      ...wizardValue,
      territoriesDeck: [
        ...wizardValue.territoriesDeck.filter((t) => t.id !== territory.id),
      ],
      selectedTerritories: [
        ...wizardValue.selectedTerritories.filter((t) => t.id !== territory.id),
      ],
    });
  };

  return (
    <>
      <Card.Title>Territories</Card.Title>
      <Card.Text className="text-body-tertiary fst-italic fs-5">
        Configure the territories available in to the campaign. Select the
        number of territories to generate and either manually select the
        territories you wish to include or click "Generate" to randomly draw
        them as described by the rulebook. Note: the tool will automatically add
        the Gang Stronghold for each player in addition to the selected
        territories.
      </Card.Text>
      <Card.Text className="text-body-tertiary fst-italic fs-5">
        It is recommended you use {wizardValue.players.length * 3} territories
        for {wizardValue.players.length} players. This tool supports a maximum
        of {maxTerritories} (excluding each gang's stronghold).
      </Card.Text>
      <div className="d-flex justify-content-end">
        {/*
        <Button
          variant="secondary"
          className="me-2"
          style={{ height: 58 }}
          onClick={handleAddTerritory}>
          + Add Custom Territory
        </Button>
        */}
        <div className="d-flex">
          <FormInput
            className="me-2"
            style={{ width: "105px" }}
            name="numberOfTerritories"
            type="number"
            min="0"
            max={maxTerritories.toString()}
            step="1"
            label="Territories"
            value={numberOfTerritories}
            onChange={handleNumberOfTerritoriesChange}
          />
          <Button
            variant="info"
            className="me-2"
            style={{ height: 58 }}
            onClick={handleGenerate}>
            Generate
          </Button>
          <Button
            variant="secondary"
            style={{ height: 58 }}
            onClick={handleClear}
            disabled={wizardValue.territoriesDeck.length === 0}>
            Clear
          </Button>
        </div>
      </div>
      {wizardValue.territoriesDeck.length < numberOfTerritories && (
        <p className="text-danger fs-6">
          Insufficient Territories available - add some Custom Territories.
        </p>
      )}
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Card.Title>Territory Deck</Card.Title>
            </Card.Header>
            <ListGroup variant="flush" style={{ fontSize: "0.8rem" }}>
              {wizardValue.territoriesDeck
                .filter(
                  (t1) =>
                    !wizardValue.selectedTerritories.some((t2) => t1 === t2),
                )
                .map((territory, index) => (
                  <ListGroup.Item key={index} className="user-select-none">
                    <div className="d-flex justify-content-between">
                      <div
                        className="flex-grow-1"
                        onClick={() => toggeTerritory(territory)}>
                        <TerritoryHeader territory={territory} />
                      </div>

                      {territory.isCustom && (
                        <>
                          <Button
                            className="px-2 py-0"
                            style={{ height: 24 }}
                            onClick={() => setEditTerritory(territory)}>
                            <FontAwesomeIcon icon={faEdit} />
                          </Button>
                          <Button
                            className="px-2 py-0"
                            style={{ height: 24 }}
                            onClick={() =>
                              handleCustomTerritoryDelete(territory)
                            }>
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </>
                      )}
                      <Button
                        className="px-2 py-0"
                        style={{ height: 24 }}
                        disabled={
                          wizardValue.selectedTerritories.length ===
                          numberOfTerritories
                        }
                        onClick={() => handleTerritoryAdd(territory)}>
                        <FontAwesomeIcon icon={faArrowRight} />
                      </Button>
                    </div>
                    {expandedTerritory === territory && (
                      <TerritoryDetails territory={territory} />
                    )}
                  </ListGroup.Item>
                ))}
            </ListGroup>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Header>
              <Card.Title>
                Campaign Territories{" "}
                <small>
                  ({wizardValue.selectedTerritories.length} of{" "}
                  {numberOfTerritories})
                </small>
              </Card.Title>
            </Card.Header>
            <ListGroup variant="flush" style={{ fontSize: "0.8rem" }}>
              {wizardValue.selectedTerritories.map((territory, index) => (
                <ListGroup.Item key={index} className="user-select-none">
                  <div className="d-flex">
                    <Button
                      className="px-2 py-0"
                      style={{ height: 24 }}
                      onClick={() => handleTerritoryRemove(territory)}>
                      <FontAwesomeIcon icon={faArrowLeft} />
                    </Button>
                    <div
                      className="flex-grow-1"
                      onClick={() => toggeTerritory(territory)}>
                      <TerritoryHeader territory={territory} />
                    </div>
                    {territory.isCustom && (
                      <>
                        <Button
                          className="px-2 py-0"
                          style={{ height: 24 }}
                          onClick={() => setEditTerritory(territory)}>
                          <FontAwesomeIcon icon={faEdit} />
                        </Button>
                        <Button
                          className="px-2 py-0"
                          style={{ height: 24 }}
                          onClick={() =>
                            handleCustomTerritoryDelete(territory)
                          }>
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      </>
                    )}
                  </div>
                  {expandedTerritory === territory && (
                    <TerritoryDetails territory={territory} />
                  )}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Card>
        </Col>
      </Row>
      <TerritoryForm
        territory={editTerritory}
        onCancel={() => setEditTerritory(undefined)}
        onSubmit={handleSaveTerritory}
      />
    </>
  );
};

export default Territories;
