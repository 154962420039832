import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { FieldArray, Formik } from "formik";
import * as Yup from "yup";

import FormikFormInput from "./formikFormInput";
import FormikFormSelect from "./formikFormSelect";

import { Battle, Player, PlayerScore } from "../types";
import { BattleResultRequest } from "../types/requests";

export interface BatteResultFormProps {
  battle: Battle | undefined;
  players: Player[];
  scores: PlayerScore[];
  onCancel: () => void;
  onSubmit: (value: BattleResultRequest) => void;
}

const validationSchema = Yup.object({
  playerScores: Yup.array()
    .of(
      Yup.object({
        userId: Yup.string().required(),
        outOfActions: Yup.number().integer().min(0).required(),
        rating: Yup.number().integer().min(0).required(),
        wealth: Yup.number().integer().min(0).required(),
        reputation: Yup.number().integer().min(0).required(),
      }),
    )
    .min(2, "There must be at least 2 players")
    .required(),
});

const BatteResultForm = ({
  battle,
  players,
  scores,
  onCancel,
  onSubmit,
}: BatteResultFormProps) => {
  const [result, setResult] = useState<BattleResultRequest | undefined>();

  useEffect(() => {
    if (!battle) {
      setResult(undefined);
    } else {
      setResult({
        winningPlayer: battle.winningPlayer ?? "",
        playerScores:
          battle.playerScores ??
          battle.players.map((p) => ({
            userId: p,
            outOfActions: 0,
            rating: scores.find((s) => s.userId === p)?.rating ?? 0,
            wealth: scores.find((s) => s.userId === p)?.wealth ?? 0,
            reputation: scores.find((s) => s.userId === p)?.reputation ?? 0,
          })),
      });
    }
  }, [battle]);

  return (
    <>
      {battle && result && (
        <Modal show={true} onHide={onCancel} backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>Update Battle Results</Modal.Title>
          </Modal.Header>

          <Formik
            initialValues={result}
            validationSchema={validationSchema}
            onSubmit={onSubmit}>
            {({ values, handleSubmit, isValid }) => (
              <Form
                noValidate
                onSubmit={handleSubmit}
                className="modal-content">
                <Modal.Body>
                  <FormikFormSelect
                    name="winningPlayer"
                    options={players
                      .filter((p) =>
                        battle.players.some(
                          (userId) => userId === p.user.userId,
                        ),
                      )
                      .map((p) => ({
                        value: p.user.userId,
                        label: `${p.gangName} (${p.user.name})`,
                      }))}
                    nullOptionDisabled={false}
                    nullOptionLabel="Draw"
                    label="Winner"
                    autoComplete="off"
                  />
                  <hr />
                  <FieldArray
                    name="playerScores"
                    render={(arrayHelpers: any) => (
                      <>
                        {values.playerScores.map((playerScore, index) => (
                          <div key={index}>
                            <h5>
                              {
                                players.find(
                                  (p) => p.user.userId === playerScore.userId,
                                )?.gangName
                              }{" "}
                              (
                              {
                                players.find(
                                  (p) => p.user.userId === playerScore.userId,
                                )?.user.name
                              }
                              )
                            </h5>
                            <FormikFormInput
                              name={`playerScores.${index}.outOfActions`}
                              type="number"
                              step="1"
                              min="0"
                              label="Fighter Taken Out of Action of Vehicles Wrecked"
                              autoComplete="off"
                            />
                            <FormikFormInput
                              name={`playerScores.${index}.rating`}
                              type="number"
                              step="1"
                              min="0"
                              label="Gang Rating after batte"
                              autoComplete="off"
                            />
                            <FormikFormInput
                              name={`playerScores.${index}.wealth`}
                              type="number"
                              step="1"
                              min="0"
                              label="Gang Wealth after Batte"
                              autoComplete="off"
                            />
                            <FormikFormInput
                              name={`playerScores.${index}.reputation`}
                              type="number"
                              step="1"
                              min="0"
                              label="Gang Reputation after Batte"
                              autoComplete="off"
                            />
                          </div>
                        ))}
                      </>
                    )}
                  />
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={onCancel}>
                    Cancel
                  </Button>
                  <Button variant="primary" disabled={!isValid} type="submit">
                    Save
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
    </>
  );
};

export default BatteResultForm;
