import { Button, Form, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Formik } from "formik";
import * as Yup from "yup";

import { Boon, Territory } from "../types";
import { BoonType } from "../types/boonType";
import { Gang } from "../types/gang";
import { TerritoryGraphic } from "../types/territoryGraphic";

import { getEnumValues } from "../services/helpers";

import FormikFormInput from "./formikFormInput";
import FormikEnumFormSelect from "./formikEnumFormSelect";

export interface TerritoryFormProps {
  territory: Territory | undefined;
  onCancel: () => void;
  onSubmit: (value: Territory) => void;
}

const boonSchema = Yup.object({
  type: Yup.mixed().oneOf(getEnumValues(BoonType)).required(),
  text: Yup.string().required(),
});

const validationSchema = Yup.object({
  name: Yup.string().required(),
  gang: Yup.mixed().oneOf(getEnumValues(Gang)).optional(),
  graphic: Yup.mixed().oneOf(getEnumValues(TerritoryGraphic)).required(),
  flavourText: Yup.string().optional(),
  boon: Yup.array().of(boonSchema).min(1),
  enhancedBoon: Yup.array()
    .of(boonSchema)
    .when("gang", {
      is: (gang: Gang | undefined) => gang === undefined,
      then: (schema) => schema.min(0).max(0),
      otherwise: (schema) => schema.min(1),
    }),
});

const TerritoryForm = ({
  territory,
  onCancel,
  onSubmit,
}: TerritoryFormProps) => {
  const addBoon = (
    values: Territory,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean,
    ) => void,
  ) => {
    const newBoon: Boon = { type: BoonType.Income, text: "" };
    const boons = [...values.boon, newBoon];
    setFieldValue("boon", boons, false);
  };

  const deleteBoon = (
    boon: Boon,
    values: Territory,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean,
    ) => void,
  ) => {
    const boons = values.boon.filter((b) => b !== boon);
    setFieldValue("boon", boons, false);
  };

  const addEnhancedBoon = (
    values: Territory,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean,
    ) => void,
  ) => {
    const newBoon: Boon = { type: BoonType.Income, text: "" };
    const enhancedBoon = values.enhancedBoon
      ? [...values.enhancedBoon, newBoon]
      : [newBoon];
    setFieldValue("enhancedBoon", enhancedBoon, false);
  };

  const deleteEnhancedBoon = (
    boon: Boon,
    values: Territory,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean,
    ) => void,
  ) => {
    if (values.enhancedBoon === undefined) return;
    const boons = values.enhancedBoon.filter((b) => b !== boon);
    setFieldValue("enhancedBoon", boons, false);
  };

  return (
    <>
      {territory && (
        <Modal show={true} onHide={onCancel} backdrop="static" scrollable>
          <Formik
            initialValues={territory}
            validationSchema={validationSchema}
            onSubmit={onSubmit}>
            {({ values, handleSubmit, isValid, setFieldValue }) => (
              <Form
                noValidate
                onSubmit={handleSubmit}
                className="modal-content">
                <Modal.Header closeButton>
                  <Modal.Title>
                    {territory.name === "" ? "Add" : "Edit"} Territory
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <FormikFormInput
                    name="name"
                    type="text"
                    label="Name"
                    autoComplete="off"
                  />
                  <FormikEnumFormSelect
                    name="graphic"
                    enumType={TerritoryGraphic}
                    label="Model"
                  />
                  <FormikEnumFormSelect
                    name="gang"
                    enumType={Gang}
                    label="Gang Preference"
                    nullOptionLabel="None"
                    nullOptionDisabled={false}
                  />
                  <FormikFormInput
                    name="flavourText"
                    type="text"
                    label="Favour Text"
                    autoComplete="off"
                    as="textarea"
                    rows={3}
                  />
                  <h5>Boon</h5>
                  {values.boon.map((boon, index) => (
                    <div key={index}>
                      <div className="d-flex justify-content-between align-items-center">
                        <FormikEnumFormSelect
                          name={`boon.${index}.type`}
                          enumType={BoonType}
                          label="Type"
                        />
                        <Button
                          size="sm"
                          onClick={() =>
                            deleteBoon(boon, values, setFieldValue)
                          }>
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      </div>
                      <FormikFormInput
                        name={`boon.${index}.text`}
                        label="Text"
                        as="textarea"
                        autoComplete="off"
                        rows={2}
                      />
                    </div>
                  ))}
                  {(!values.boon || values.boon.length === 0) && <p>None</p>}

                  <h5>Enhanced Boon</h5>
                  {values.enhancedBoon?.map((boon, index) => (
                    <div key={index}>
                      <div className="d-flex justify-content-between align-items-center">
                        <FormikEnumFormSelect
                          name={`enhancedBoon.${index}.type`}
                          enumType={BoonType}
                          label="Type"
                        />
                        <Button
                          size="sm"
                          onClick={() =>
                            deleteEnhancedBoon(boon, values, setFieldValue)
                          }>
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      </div>
                      <FormikFormInput
                        name={`enhancedBoon.${index}.text`}
                        label="Text"
                        as="textarea"
                        autoComplete="off"
                        rows={2}
                      />
                    </div>
                  ))}
                  {(!values.enhancedBoon ||
                    values.enhancedBoon.length === 0) && <p>None</p>}
                  <div className="d-flex">
                    <Button
                      size="sm"
                      className="me-2"
                      onClick={() => addBoon(values, setFieldValue)}>
                      Add Boon
                    </Button>
                    <Button
                      size="sm"
                      onClick={() => addEnhancedBoon(values, setFieldValue)}
                      disabled={values.gang === undefined}>
                      Add Enhanced Boon
                    </Button>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  {isValid}
                  <Button variant="secondary" onClick={onCancel}>
                    Cancel
                  </Button>
                  <Button variant="primary" disabled={!isValid} type="submit">
                    Save
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
    </>
  );
};

export default TerritoryForm;
