import { useField, FieldHookConfig } from "formik";
import { FormSelectProps } from "react-bootstrap";

import { getEnumKeys } from "../services/helpers";

import FormSelect from "./formSelect";

export interface FormikEnumFormSelectProps extends FormSelectProps {
  enumType: any;
  label: string;
  nullOptionLabel?: string;
  nullOptionDisabled?: boolean;
}

const FormikEnumFormSelect = ({
  enumType,
  ...props
}: FormikEnumFormSelectProps & FieldHookConfig<number | undefined>) => {
  const [{ ...field }, { error, touched }, { setValue }] = useField(props);

  return (
    <FormSelect
      {...field}
      {...props}
      onChange={(e) =>
        setValue(e.target.value === "" ? undefined : Number(e.target.value))
      }
      isValid={touched && !error}
      isInvalid={touched && error !== undefined}
      options={getEnumKeys(enumType).map((k) => ({
        value: enumType[k] as number,
        label: k as string,
      }))}
    />
  );
};

export default FormikEnumFormSelect;
