export enum TerritoryGraphic {
  "Old Ruins",
  "Settlement",
  "Rogue Doc",
  "Promethium Cache",
  "Wastes",
  "Sludge Sea",
  "Workshop",
  "Collapsed Dome",
  "Refuse Drift",
  "Corpse Farm",
  "Bone Shrine",
  "Drinking Hole",
  "Gambling Den",
  "Needle Ways",
  "Synth Still",
  "Stinger Mould Sprawl",
  "Narco Den",
  "Slag Furnace",
  "Fighting Pits",
  "Smelting Works",
  "Mine Workings",
  "Tunnels",
  "Toll Crossing",
  "Generatorium",
  "Archaeotech Device",
  "Tech Bazaar",
}
