import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, Card, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";

import LoadingSpinner from "../components/loadingSpinner";

import { useApiContext } from "../api/apiContext";

const schema = yup.object({
  email: yup
    .string()
    .email("Must be a valid email")
    .required("Email is required"),
  password: yup
    .string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      "Password not complex enough",
    )
    .required("Password is required"),
  password2: yup
    .string()
    .oneOf([yup.ref("password"), ""], "Passwords must match")
    .required("Please repeat your password"),
});

const ResetPassword = () => {
  const apiContext = useApiContext();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);

  var key = searchParams.get("key");

  const handleSubmit = (values: {
    email: string;
    password: string;
    password2: string;
  }) => {
    if (!key) return;
    setLoading(true);
    apiContext
      .resetPassword(values.email, values.password, key)
      .then(() => navigate("/login"))
      .finally(() => setLoading(false));
  };

  return (
    <>
      {loading && <LoadingSpinner />}
      <div className="d-flex align-items-stretch">
        <Formik
          validationSchema={schema}
          onSubmit={handleSubmit}
          initialValues={{
            email: "",
            password: "",
            password2: "",
          }}>
          {({
            handleChange,
            values,
            errors,
            handleBlur,
            touched,
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit} className="flex-fill mx-3">
              <Card>
                <Card.Header>Register</Card.Header>
                <Card.Body>
                  <Form.Group className="position-relative mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Email"
                      onChange={handleChange}
                      value={values.email}
                      isInvalid={touched.email && !!errors.email}
                      onBlur={handleBlur}
                      name="email"
                    />
                    <Form.Control.Feedback type="invalid" tooltip={true}>
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="position-relative mb-3">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      onChange={handleChange}
                      value={values.password}
                      isInvalid={touched.password && !!errors.password}
                      onBlur={handleBlur}
                      name="password"
                    />
                    <Form.Control.Feedback type="invalid" tooltip={true}>
                      {errors.password}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="position-relative mb-3">
                    <Form.Label>Retype Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Retype password"
                      onChange={handleChange}
                      value={values.password2}
                      isInvalid={touched.password2 && !!errors.password2}
                      onBlur={handleBlur}
                      name="password2"
                    />
                    <Form.Control.Feedback type="invalid" tooltip={true}>
                      {errors.password2}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Card.Body>
                <Card.Footer className="text-end">
                  <Button variant="primary" type="submit" disabled={loading}>
                    Change Password
                  </Button>
                </Card.Footer>
              </Card>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default ResetPassword;
