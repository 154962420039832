import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card } from "react-bootstrap";

import { useApiContext } from "../../api/apiContext";
import { useAuthContext } from "../../auth/authContext";
import { SetupContext, Wizard } from "./setupContext";

import DefaultTerritories from "../../types/defaultTerritories";

import Players from "./players";
import Territories from "./territories";
import Map from "./map";
import Preview from "./preview";
import Name from "./name";
import LoadingSpinner from "../../components/loadingSpinner";

const Setup = () => {
  const apiContext = useApiContext();
  const authContext = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!authContext.token) {
      navigate(`/login?return=${encodeURI("/setup")}`);
    }
  }, [authContext.token, navigate]);

  const intialValue: Wizard = {
    sandboxMode: false,
    currentStep: 1,
    name: "",
    flavourText: "",
    players: [],
    territoriesDeck: DefaultTerritories,
    selectedTerritories: [],
    campaignTerritories: [],
    domes: [],
    isValid: [false, false, false, false, true],
  };
  const [wizard, setWizard] = useState<Wizard>(intialValue);
  const [loading, setLoading] = useState(false);

  const steps = [<Name />, <Players />, <Territories />, <Map />, <Preview />];

  const handlePrevStep = () => {
    if (wizard.currentStep === 1) return;
    setWizard({
      ...wizard,
      currentStep: wizard.currentStep - 1,
    });
  };

  const handleNextStep = () => {
    if (wizard.currentStep === steps.length) return;
    setWizard({
      ...wizard,
      currentStep: wizard.currentStep + 1,
    });
  };

  const handleStart = () => {
    setLoading(true);
    apiContext
      .createCampaign({
        name: wizard.name,
        flavourText: wizard.flavourText,
        players: wizard.players.map((player) => ({
          userId: player.user.userId,
          gangName: player.gangName,
          gang: player.gang,
          color: player.color,
        })),
        domes: wizard.domes,
        territories: wizard.campaignTerritories,
      })
      .then((campaignId) => {
        navigate(`/campaigns/${campaignId}`);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      {loading && <LoadingSpinner />}
      <SetupContext.Provider value={{ value: wizard, update: setWizard }}>
        <Card>
          <Card.Header>
            <Card.Title>Setup your Campaign</Card.Title>
          </Card.Header>
          <Card.Body>{steps[wizard.currentStep - 1]}</Card.Body>
          <Card.Footer className="d-flex justify-content-end align-items-center">
            {wizard.sandboxMode && (
              <div className="flex-grow-1">
                <span className="badge rounded-pill text-bg-danger">
                  SANDBOX MODE
                </span>
              </div>
            )}
            <div>
              <Button
                variant="secondary"
                className="me-1"
                disabled={wizard.currentStep === 1}
                onClick={handlePrevStep}>
                Previous
              </Button>
              {wizard.currentStep < steps.length && (
                <Button
                  variant="primary"
                  disabled={!wizard.isValid[wizard.currentStep - 1]}
                  onClick={handleNextStep}>
                  Next
                </Button>
              )}
              {wizard.currentStep === steps.length && (
                <Button
                  variant="primary"
                  onClick={handleStart}
                  disabled={wizard.sandboxMode}>
                  Start Campaign
                </Button>
              )}
            </div>
          </Card.Footer>
        </Card>
      </SetupContext.Provider>
    </>
  );
};

export default Setup;
