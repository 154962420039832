import { useEffect, useRef, useState } from "react";
import { Alert, Button, Card, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faEye } from "@fortawesome/free-solid-svg-icons";

import { useSetupContext } from "./setupContext";

import ThreeService from "../../services/threeService";
import ThreeFactory from "../../services/threeFactory";

import { ControlledBy, TerritoryDetails } from "../../components";
import LoadingSpinner from "../../components/loadingSpinner";

const Preview = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const threeRef = useRef<ThreeService | undefined>();
  const { value: wizardValue } = useSetupContext();

  const [loading, setLoading] = useState(true);
  const [selectedTerritoryId, setSelectedTerritoryId] = useState<
    number | undefined
  >();

  useEffect(
    () => {
      return () => {
        if (threeRef.current) threeRef.current.dispose();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (!canvasRef.current) return;

    ThreeFactory.create(
      canvasRef.current,
      wizardValue.campaignTerritories,
    ).then((three) => {
      if (!canvasRef.current)
        throw new Error("Expecting canvasRef to have a value");
      three.onSelect = setSelectedTerritoryId;
      three.updateSize();
      three.domes = wizardValue.domes;
      wizardValue.campaignTerritories.forEach((territory) => {
        three.updateTerritory(
          territory,
          wizardValue.players.find(
            (p) => p.user.userId === territory.controlledByUserId,
          )?.color,
        );
      });
      threeRef.current = three;
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvasRef]);

  useEffect(() => {
    if (!threeRef.current) return;
    threeRef.current.updateSize();
  }, [selectedTerritoryId]);

  const handleSelectNone = () => {
    if (!threeRef.current) return;
    threeRef.current.selectNone();
    setSelectedTerritoryId(undefined);
  };

  return (
    <>
      {loading && <LoadingSpinner />}
      <Card.Title>Preview</Card.Title>
      <Card.Text className="text-body-tertiary fst-italic fs-5">
        Review the campaign map. You can pan and zoom into the map using your
        mouse. Clicking on the territory tiles will display the details for that
        territory on the right. If you are happy with your campaign, click
        "Start Campaign" to begin.
      </Card.Text>
      <Row>
        <Col>
          <Button
            className="px-2 py-0"
            style={{ height: 24, position: "absolute" }}
            onClick={handleSelectNone}>
            <FontAwesomeIcon icon={faEye} /> Reset
          </Button>
          <canvas ref={canvasRef} className="d-block w-100" height={400} />
        </Col>
        {selectedTerritoryId !== undefined &&
          wizardValue.campaignTerritories
            .filter((t) => t.id === selectedTerritoryId)
            .map((territory) => (
              <Col key={territory.id}>
                <Card>
                  <Card.Header>{territory.name}</Card.Header>
                  <Card.Body className="fs-6">
                    <p>
                      <span className="text-info">Controlled By</span>{" "}
                      <ControlledBy
                        userId={territory.controlledByUserId}
                        players={wizardValue.players}
                      />
                    </p>
                    <TerritoryDetails territory={territory} />
                  </Card.Body>
                </Card>
              </Col>
            ))}
      </Row>
      <Row>
        <Col>
          <Alert variant="warning" className="mt-2">
            <FontAwesomeIcon icon={faCircleInfo} /> The territory tiles are the
            work of{" "}
            <a
              href="https://www.myminifactory.com/users/PopovLaboratory"
              target="_blank"
              rel="noreferrer">
              Papov
            </a>
            . If you like what you see and would ike to support the artist, or
            would like to purchase these assets for 3D printing, head over to
            his page on{" "}
            <a
              href="https://www.myminifactory.com/users/PopovLaboratory"
              target="_blank"
              rel="noreferrer">
              MyMiniFactory
            </a>
            .
          </Alert>
        </Col>
      </Row>
    </>
  );
};

export default Preview;
