import { Button, ListGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

import { TerritoryLocation } from "../types";

export interface UnassignedTerritoryItemProps {
  territory: TerritoryLocation;
  selectedTerritoryId: number | undefined;
  onTerritoryEdit: (territoryId: number) => void;
}

const UnassignedTerritoryItem = ({
  territory,
  selectedTerritoryId,
  onTerritoryEdit,
}: UnassignedTerritoryItemProps) => {
  return (
    <ListGroup.Item
      active={selectedTerritoryId === territory.id}
      className="user-select-none d-flex align-items-center">
      <span className="flex-grow-1">{territory.name}</span>
      <Button
        className="px-2 py-0"
        style={{ height: 24 }}
        onClick={() => onTerritoryEdit(territory.id)}>
        <FontAwesomeIcon icon={faEdit} />
      </Button>
    </ListGroup.Item>
  );
};

export default UnassignedTerritoryItem;
