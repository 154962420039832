import { Player } from "../types";

export interface ControlledByProps {
  userId?: string;
  players: Player[];
}

const ControlledBy = ({ userId, players }: ControlledByProps) => {
  const player = players.find((p) => p.user.userId === userId);
  return (
    <>
      {player === undefined && <span className="fst-italic">None</span>}
      {player !== undefined && (
        <>
          <span className="fw-bold">{player.gangName}</span> ({player.user.name}
          )
        </>
      )}
    </>
  );
};

export default ControlledBy;
