import { Container, Dropdown, Navbar } from "react-bootstrap";
import { Outlet, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

import { useAuthContext } from "../auth/authContext";
import { useApiContext } from "../api/apiContext";

const Layout = () => {
  const apiContext = useApiContext();
  const authContext = useAuthContext();
  const navigate = useNavigate();

  const logout = () => {
    apiContext.logout().finally(() => {
      navigate("/logout");
    });
  };

  return (
    <Container fluid={true} className="px-0" data-bs-theme="dark">
      <Navbar expand="lg" className="bg-body-tertiary mb-4">
        <Container fluid={true}>
          <Navbar.Brand href="/">
            <img
              alt=""
              src="/logo.png"
              width="30"
              height="30"
              className="d-inline-block align-top"
            />{" "}
            Hive Dominion
          </Navbar.Brand>
          <div className="d-flex align-items-center">
            {authContext.currentUser && (
              <>
                <span className="me-3">Hi {authContext.currentUser.name}</span>
                <Dropdown>
                  <Dropdown.Toggle size="sm">
                    <FontAwesomeIcon icon={faUser} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="end">
                    <Dropdown.Item href="/updateAccount">
                      Update Account
                    </Dropdown.Item>
                    <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </>
            )}
            {!authContext.currentUser && (
              <>
                <a href="/login">Login</a>
              </>
            )}
          </div>
        </Container>
      </Navbar>
      <Container>
        <Outlet />
      </Container>
    </Container>
  );
};

export default Layout;
