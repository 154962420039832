import { useEffect, useMemo, useState } from "react";
import jwt_decode from "jwt-decode";

import { AuthContext } from "./authContext";

export interface AuthProps {
  children: React.ReactNode;
}

const AUTH_TOKEN_KEY = "authToken";

const AuthProdider = ({ children }: AuthProps) => {
  const [token, setToken] = useState<string | undefined>(
    localStorage.getItem(AUTH_TOKEN_KEY) ?? undefined,
  );
  const currentUser = useMemo(() => {
    try {
      if (!token) return undefined;
      const jwt = jwt_decode(token) as any;
      if (Number(new Date()) / 1000 > jwt.exp) {
        setToken(undefined);
      }
      return {
        userId: jwt.UserId,
        email: jwt.Email,
        name: jwt.Name,
      };
    } catch {
      return undefined;
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      localStorage.setItem(AUTH_TOKEN_KEY, token);
    } else {
      localStorage.removeItem(AUTH_TOKEN_KEY);
    }
  }, [token]);

  const login = (token: string) => {
    setToken(token);
  };

  const logout = () => {
    setToken(undefined);
  };

  return (
    <AuthContext.Provider value={{ token, currentUser, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProdider;
