import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";

import { Player } from "../types";
import { Gang, GangOrOther } from "../types/gang";

import { enumEquals, getEnumValues } from "../services/helpers";

import FormikFormInput from "./formikFormInput";
import FormikEnumFormSelect from "./formikEnumFormSelect";
import { useApiContext } from "../api/apiContext";
import { useState } from "react";
import LoadingSpinner from "./loadingSpinner";

export interface PlayerFormProps {
  player: Player | undefined;
  sandboxMode: boolean;
  onCancel: () => void;
  onSubmit: (value: Player) => void;
}

const validationSchema = Yup.object({
  user: Yup.object({
    userId: Yup.string().required("Please select a user"),
    email: Yup.string().email().required("Please select a user"),
    name: Yup.string(),
  }).required("Please select a user"),
  gangName: Yup.string().required("Please enter a name for the gang"),
  color: Yup.string().required("Please select a color for the gang"),
  gang: Yup.object({
    houseGang: Yup.mixed()
      .oneOf(getEnumValues(GangOrOther))
      .required("Please select the gang's house"),
    customGangType: Yup.string().when("houseGang", {
      is: (houseGang: GangOrOther) =>
        enumEquals(GangOrOther, GangOrOther.Other, houseGang),
      then: (schema) => schema.required("Please enter the house's gang type"),
      otherwise: (schema) => schema.optional(),
    }),
    territoryGang: Yup.mixed().oneOf(getEnumValues(Gang)).optional(),
  }).required(),
});

const PlayerForm = ({
  player,
  sandboxMode,
  onCancel,
  onSubmit,
}: PlayerFormProps) => {
  const apiContext = useApiContext();
  const [hasSearched, setHasSearched] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <>
      {loading && <LoadingSpinner />}
      {player && (
        <Modal show={true} onHide={onCancel} backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>
              {player.user.userId === "" ? "Add" : "Edit"} Player
            </Modal.Title>
          </Modal.Header>

          <Formik
            initialValues={player}
            validationSchema={validationSchema}
            onSubmit={onSubmit}>
            {({
              values,
              handleSubmit,
              isValid,
              errors,
              setFieldValue,
              setFieldError,
            }) => (
              <Form
                noValidate
                onSubmit={handleSubmit}
                className="modal-content">
                <Modal.Body>
                  {!sandboxMode && (
                    <>
                      <InputGroup>
                        <FormikFormInput
                          name="user.email"
                          type="email"
                          label="Player's Email"
                          onBlur={() => {
                            setLoading(true);
                            apiContext
                              .userList(values.user.email)
                              .then((users) => {
                                if (users.length === 1) {
                                  setFieldValue("user", users[0], true);
                                } else {
                                  setFieldValue(
                                    "user",
                                    {
                                      userId: "",
                                      name: "",
                                      email: values.user.email,
                                    },
                                    false,
                                  );
                                  setFieldError("user.email", "User not found");
                                }
                                setHasSearched(true);
                              })
                              .finally(() => setLoading(false));
                          }}
                        />
                        <Button
                          disabled={errors.user?.email !== undefined}
                          className="mb-3">
                          Find
                        </Button>
                      </InputGroup>
                      {!values.user.userId && hasSearched && (
                        <p className="text-danger">
                          User not found. Please check their email address and
                          ensure they are already registered.
                        </p>
                      )}
                    </>
                  )}
                  {(values.user.userId || sandboxMode) && (
                    <>
                      {!sandboxMode && (
                        <>
                          <FormikFormInput
                            name="user.name"
                            type="text"
                            label="Player Name"
                            readOnly={true}
                          />
                        </>
                      )}
                      <FormikFormInput
                        name="gangName"
                        type="text"
                        label="Gang Name"
                        autoComplete="off"
                      />
                      <FormikEnumFormSelect
                        name="gang.houseGang"
                        enumType={GangOrOther}
                        label="House Gang"
                        nullOptionLabel="Select Gang"
                      />
                      {enumEquals(
                        GangOrOther,
                        values.gang.houseGang,
                        GangOrOther.Other,
                      ) && (
                        <>
                          <FormikFormInput
                            name="gang.customGangType"
                            type="text"
                            label="Gang Type"
                            autoComplete="off"
                          />
                          <FormikEnumFormSelect
                            name="gang.territoryGang"
                            enumType={Gang}
                            label="For Territory Boon, Counts As"
                            nullOptionDisabled={false}
                            nullOptionLabel="None"
                          />
                        </>
                      )}
                      <FormikFormInput
                        name="color"
                        type="color"
                        label="Color"
                        autoComplete="off"
                        style={{ width: "100%" }}
                      />
                    </>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={onCancel}>
                    Cancel
                  </Button>
                  <Button variant="primary" disabled={!isValid} type="submit">
                    Save
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
    </>
  );
};

export default PlayerForm;
