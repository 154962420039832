import { Card } from "react-bootstrap";

const Logout = () => {
  return (
    <>
      <Card className="w-50 mx-auto">
        <Card.Header>You have been successfully logged out</Card.Header>
        <Card.Body>
          <p>
            Thank you for using our website. If you'd like to log in again,
            please click the "Login" button.
          </p>
          <p>
            If you have any suggestions or feature requests please drop us an
            email at{" "}
            <a href="mailto:admin@hivedominion.co.uk">
              admin@hivedominion.co.uk
            </a>
          </p>
          <p>Stay safe out there scummer!</p>
        </Card.Body>
      </Card>
    </>
  );
};

export default Logout;
