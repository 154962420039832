import { Button, Card } from "react-bootstrap";

const Home = () => {
  return (
    <>
      <Card className="w-75 mx-auto">
        <Card.Img variant="top" src="welcome.png" />
        <Card.Header>Welcome to Hive Dominion</Card.Header>
        <Card.Body>
          <p>
            Here you can bring the nightmarish underhive of Necromunda to life.
            Build your campaign and share it with your gaming group, or
            participate in the campaign set out by your arbitrator.
          </p>
          <p>
            This tool provides the means to manage your campaign and visualise
            your own little corner of the underhive. In collaboration with the
            artist Papov, Hive Dominion gives you 3D tiles for each of your
            territories.
          </p>
          <p>This tool assists the Arbitrator by</p>
          <ul>
            <li>
              Simple setup you campaign without having to build complicated
              territory decks
            </li>
            <li>
              Allows customisation of territories to add your own flavour to the
              campaign; is your local doc got a name, why not rename the Rogue
              Doc territory
            </li>
            <li>Limits challenges to available territories</li>
            <li>
              Keeping track of gang ratings, wealth, rep and Out of Action
              results
            </li>
            <li>
              Easy visualisation of which players control which territories with
              a beautiful interactive 3D map
            </li>
            <li>
              Gives a continuous leaderboard - handy when needing to accept
              challenges in rating order
            </li>
            <li>Automatically works out the Triumphs</li>
          </ul>
          <p>And for the players…</p>
          <ul>
            <li>Handy reference to the boons granted by each territory</li>
            <li>
              Easily See which territories your opponent's control to speed up
              challenges
            </li>
            <li>Battle-by-battle reply, to show how the campaign played out</li>
          </ul>
          <p>...and loads more features to come too.</p>
          <p>So why not make it your own and start now!</p>
          <hr />
          <h4>16/11/23 - Update Aranthus</h4>
          <p>
            Hey There Scummers! I've had some useful initial feedback on the app
            and I've made some improvements...
          </p>
          <ul>
            <li>
              Terms and conditions page is now linked - silly oversight on my
              part.
            </li>
            <li>More instructions on the Campaign Creator.</li>
            <li>
              Fixed issue where "Assign territory to slot" drop down appeared
              below the menu.
            </li>
            <li>
              Lots of people said they couldn't get past the "Adding Players"
              page - You need a minimum of 3 players to make a Dominion
              campaign, so I've added a warning message to make this more clear.
            </li>
            <li>
              Some people just wanted to play with the tool without having their
              group register - I've added a Sandbox Mode to allow this. You no
              longer need to add real players, but it will not allow you to save
              the campaign while in Sandbox mode. Go have a play and hopefully
              be inspired to use it for real with your gaming group!
            </li>
          </ul>
          <p>
            Any other suggestions or bug? Let me know{" "}
            <a href="mailto:admin@hivedominion.co.uk">
              admin@hivedominion.co.uk
            </a>
          </p>
        </Card.Body>
        <Card.Footer className="text-end">
          <Button variant="primary" href="/campaigns">
            Get Started
          </Button>
        </Card.Footer>
      </Card>
    </>
  );
};

export default Home;
