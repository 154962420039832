import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  Col,
  Row,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleInfo,
  faCity,
  faClose,
  faCoins,
  faEye,
  faHandshake,
  faPersonRifle,
  faSkullCrossbones,
} from "@fortawesome/free-solid-svg-icons";

import { useApiContext } from "../api/apiContext";
import { useAuthContext } from "../auth/authContext";

import { AddBattleForm, ControlledBy, TerritoryDetails } from "../components";

import {
  Battle,
  Campaign,
  CampaignEvent,
  CampaignWeek,
  Player,
  PlayerCampaignScore,
  TerritoryLocation,
} from "../types";
import {
  BattleResultRequest,
  BattleRequest,
  CampaignWeekReqest,
} from "../types/requests";

import ThreeService from "../services/threeService";
import ThreeFactory from "../services/threeFactory";
import BattleDetails from "../components/battleDetails";
import LoadingSpinner from "../components/loadingSpinner";
import BatteResultForm from "../components/battleResultForm";
import { CampaignPhase } from "../types/campaignPhase";
import NewWeekForm from "../components/newWeekForm";
import EndCampaign from "../components/endCampaign";
import CampaignEventDisplay from "../components/campaignEventDisplay";
import Triumph from "../components/triumph";
import { clone } from "../services/helpers";

const Home = () => {
  var apiContext = useApiContext();
  var authContext = useAuthContext();
  const navigate = useNavigate();
  const { campaignId } = useParams();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const threeRef = useRef<ThreeService | undefined>();

  const [loading, setLoading] = useState(true);
  const [campaign, setCampaign] = useState<Campaign | undefined>();
  const [selectedTerritoryId, setSelectedTerritoryId] = useState<
    number | undefined
  >();
  const [tab, setTab] = useState<string>("battles");
  const [showAddBattle, setShowAddBattle] = useState(false);
  const [showNewWeek, setShowNewWeek] = useState(false);
  const [showEndDialogue, setShowEndDialogue] = useState(false);
  const [editBattle, setEditBattle] = useState<Battle | undefined>();
  const [scores, setScores] = useState<PlayerCampaignScore[]>([]);
  const [availableTerritories, setavailableTerritories] = useState<
    TerritoryLocation[]
  >([]);
  const [allTerritoriesClaimed, setAllTerritoriesClaimed] = useState(false);
  const [replayMode, setReplayMode] = useState(false);
  const [latestWeek, setLatestWeek] = useState<CampaignWeek | undefined>();
  const [campaignEvents, setCampaignEvents] = useState<CampaignEvent[]>([]);
  const [campaignEventIndex, setCampaignEventIndex] = useState(0);

  useEffect(() => {
    if (!campaignId) navigate("/campaigns");
    apiContext.getCampaign(campaignId as string).then((campaign) => {
      if (!campaign) navigate("/campaigns");
      setCampaign(campaign);
    });
    return () => {
      if (threeRef.current) threeRef.current.dispose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!canvasRef.current || !campaign) return;

    ThreeFactory.create(canvasRef.current, campaign.territories).then(
      (three) => {
        if (!canvasRef.current)
          throw new Error("Expecting canvasRef to have a value");
        three.onSelect = setSelectedTerritoryId;
        three.updateSize();
        three.domes = campaign.domes;
        campaign.territories.forEach((territory) => {
          three.updateTerritory(
            territory,
            campaign.players.find(
              (p) => p.user.userId === territory.controlledByUserId,
            )?.color,
          );
        });
        threeRef.current = three;
        updateCampaign();
        setLoading(false);
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvasRef, campaign]);

  useEffect(() => {
    if (!authContext.token) {
      navigate(`/login?return=${encodeURI(`/campaigns/${campaignId}`)}`);
    }
  }, [authContext.token, navigate, campaignId]);

  useEffect(() => {
    if (!threeRef.current) return;
    threeRef.current.updateSize();
  }, [selectedTerritoryId]);

  const updateCampaign = () => {
    if (!campaign || !threeRef.current) return;

    if (campaign.isEnded) setTab("triumphs");

    const territoryOwnership: { [id: number]: TerritoryLocation } =
      Object.assign(
        {},
        ...campaign.territories.map((t) => ({
          [t.id]: clone(t),
        })),
      );

    const playerScores: { [id: string]: PlayerCampaignScore } = Object.assign(
      {},
      ...campaign.players.map((p) => ({
        [p.user.userId]: {
          userId: p.user.userId,
          battles: 0,
          territories: Object.values(territoryOwnership).filter(
            (t) => t.controlledByUserId === p.user.userId,
          ).length,
          outOfActions: 0,
          rating: 1000,
          wealth: 1000,
          reputation: 0,
        },
      })),
    );

    const campaignEvents: CampaignEvent[] = [];
    let latestWeek = campaign.campaignWeeks[0];
    campaign.campaignWeeks.forEach((week) => {
      latestWeek = week;

      campaignEvents.push({
        week,
        players: campaign.players,
        territoryOwnership: clone(territoryOwnership),
        leaderBoard: clone(Object.values(playerScores)),
      });
      week.battles
        .sort((a, b) => (new Date(a.date) < new Date(b.date) ? -1 : 1))
        .forEach((battle) => {
          const players = battle.players.map((userId) =>
            campaign.players.find((p) => p.user.userId === userId),
          ) as Player[];

          campaignEvents.push({
            week,
            battle: {
              ...battle,
              winningPlayer: undefined,
              playerScores: undefined,
            },
            players,
            territoryOwnership: clone(territoryOwnership),
            leaderBoard: clone(Object.values(playerScores)),
          });

          if (battle.playerScores) {
            if (battle.winningPlayer && battle.territoryId) {
              territoryOwnership[battle.territoryId].controlledByUserId =
                battle.winningPlayer;
            }

            battle.playerScores.forEach((s) => {
              playerScores[s.userId].battles += 1;
              playerScores[s.userId].outOfActions += s.outOfActions;
              playerScores[s.userId].rating = s.rating;
              playerScores[s.userId].wealth = s.wealth;
              playerScores[s.userId].reputation = s.reputation;
              playerScores[s.userId].territories = Object.values(
                territoryOwnership,
              ).filter((t) => t.controlledByUserId === s.userId).length;
            });

            campaignEvents.push({
              week,
              battle,
              players,
              territoryOwnership: clone(territoryOwnership),
              leaderBoard: clone(Object.values(playerScores)),
            });
          }
        });
    });

    Object.values(territoryOwnership).forEach((territory) => {
      if (territory.controlledByUserId) {
        if (!threeRef.current) return;
        const color = campaign.players.find(
          (p) => p.user.userId === territory.controlledByUserId,
        )?.color;
        threeRef.current.updateTerritory(territory, color);
      }
    });

    const allTerritoriesClaimed = !Object.values(territoryOwnership).some(
      (t) => !t.controlledByUserId,
    );
    setAllTerritoriesClaimed(allTerritoriesClaimed);
    setScores(Object.values(playerScores));
    setLatestWeek(latestWeek);
    setCampaignEvents(campaignEvents);

    if (latestWeek !== undefined) {
      if (latestWeek.phase === CampaignPhase.Occupation) {
        setavailableTerritories(
          campaign.territories
            .filter(
              (t) =>
                t.id > 0 &&
                !territoryOwnership[t.id].controlledByUserId &&
                !latestWeek.battles
                  .filter((b) => !b.playerScores)
                  .some((b) => b.territoryId === t.id),
            )
            .sort((a, b) => (a.name < b.name ? -1 : 1)),
        );
      } else {
        setavailableTerritories(
          campaign.territories
            .filter(
              (t) =>
                t.id > 0 &&
                !latestWeek.battles
                  .filter((b) => !b.playerScores)
                  .some((b) => b.territoryId === t.id),
            )
            .sort((a, b) => (a.name < b.name ? -1 : 1)),
        );
      }
    }
  };

  const handleTerritorySelect = (territoryId: number) => {
    if (!threeRef.current) return;
    setSelectedTerritoryId(territoryId);
    threeRef.current.selectTerritory(territoryId);
  };

  const handleSelectNone = () => {
    if (!threeRef.current) return;
    threeRef.current.selectNone();
    setSelectedTerritoryId(undefined);
  };

  const handleAddBattle = (battle: BattleRequest) => {
    if (!campaign || !latestWeek || !campaignId) return;
    apiContext
      .createBattle(campaignId, {
        ...battle,
        date: new Date(battle.date).toJSON(),
      })
      .then((battle) => {
        if (!battle) return;
        latestWeek.battles.push(battle);
        setShowAddBattle(false);
        updateCampaign();
      });
  };

  const handleDeleteBattle = (battle: Battle) => {
    if (!campaign || !latestWeek || !campaignId) return;
    if (
      window.confirm("Are you sure you wish to delete this battle?") === true
    ) {
      apiContext.deleteBattle(campaignId, battle.id).then(() => {
        latestWeek.battles = latestWeek.battles.filter(
          (b) => b.id !== battle.id,
        );
      });
      updateCampaign();
    }
  };

  const handleUpdateBattleResult = (result: BattleResultRequest) => {
    if (!campaign || !latestWeek || !editBattle || !campaignId) return;
    apiContext.updateBatteResult(campaignId, editBattle.id, result).then(() => {
      var battle = latestWeek.battles.find((b) => b.id === editBattle.id);
      if (!battle) return;
      battle.playerScores = result.playerScores;
      battle.winningPlayer = result.winningPlayer;
      setEditBattle(undefined);
      updateCampaign();
    });
  };

  const handleStartNextWeek = (request: CampaignWeekReqest) => {
    if (!campaign || !campaignId) return;
    apiContext.startNextCampaignWeek(campaignId, request).then((week) => {
      if (!week) return;
      campaign.campaignWeeks.push(week);
      setShowNewWeek(false);
      updateCampaign();
    });
  };

  const handleEndCampaign = () => {
    if (!campaign || !campaignId) return;
    apiContext.endCampaign(campaignId).then(() => {
      campaign.isEnded = true;
      updateCampaign();
    });
  };

  const displayPhase = (phase: number) => {
    switch (phase) {
      case 0:
        return "Occupation Phase";
      case 1:
        return "Down Time";
      case 2:
        return "Take Over Phase";
    }
  };

  const displayGangAndPlayer = (userId: string) => {
    if (!campaign) return "";
    const player = campaign.players.find((p) => p.user.userId === userId);
    if (!player) return "";
    return `${player.gangName} (${player.user.name})`;
  };

  const startCampaignReplay = () => {
    setReplayMode(true);
    setCampaignEventIndex(0);
    replayUpdateMap(campaignEvents[0]);
  };

  const nextCampaignEvent = () => {
    setCampaignEventIndex(campaignEventIndex + 1);
    replayUpdateMap(campaignEvents[campaignEventIndex + 1]);
  };

  const previousCampaignEvent = () => {
    setCampaignEventIndex(campaignEventIndex - 1);
    replayUpdateMap(campaignEvents[campaignEventIndex - 1]);
  };

  const replayUpdateMap = (event: CampaignEvent) => {
    if (!campaign) return;
    Object.values(event.territoryOwnership).forEach((territory) => {
      if (!threeRef.current) return;
      const color = campaign.players.find(
        (p) => p.user.userId === territory.controlledByUserId,
      )?.color;
      threeRef.current.updateTerritory(territory, color);
    });

    if (event.battle?.territoryId) {
      threeRef.current?.selectTerritory(event.battle?.territoryId);
    } else {
      threeRef.current?.selectNone();
    }
  };

  return (
    <>
      {loading && <LoadingSpinner />}
      {campaign && (
        <>
          <Card>
            <Card.Header>
              <Card.Title>{campaign.name}</Card.Title>
            </Card.Header>
            <Card.Body>
              <p>{campaign.flavourText}</p>
              {latestWeek && !campaign.isEnded && !replayMode && (
                <>
                  <h4>
                    Week {latestWeek.weekNumber}:{" "}
                    {displayPhase(latestWeek.phase)}
                  </h4>
                </>
              )}
              <Row>
                <Col>
                  <Button
                    className="px-2 py-0"
                    style={{ height: 24, position: "absolute" }}
                    onClick={handleSelectNone}>
                    <FontAwesomeIcon icon={faEye} /> Reset
                  </Button>
                  <canvas
                    ref={canvasRef}
                    className="d-block w-100"
                    height={400}
                  />
                </Col>
                <Col>
                  {replayMode && (
                    <>
                      <CampaignEventDisplay
                        event={campaignEvents[campaignEventIndex]}
                      />
                    </>
                  )}

                  {!replayMode && selectedTerritoryId === undefined && (
                    <>
                      <Tabs
                        activeKey={tab}
                        onSelect={(k) => setTab(k ?? "battles")}
                        className="mb-3">
                        {!campaign.isEnded && (
                          <Tab eventKey="battles" title="Battles">
                            {latestWeek &&
                              latestWeek.battles
                                .sort((a, b) =>
                                  new Date(a.date) < new Date(b.date) ? -1 : 1,
                                )
                                .map((b) => (
                                  <BattleDetails
                                    key={b.id}
                                    battle={b}
                                    players={campaign.players}
                                    canEdit={
                                      !campaign.isEnded &&
                                      authContext.currentUser?.userId ===
                                        campaign.owner?.userId
                                    }
                                    isCurrentWeek={true}
                                    territories={campaign.territories}
                                    onSelectTerritory={handleTerritorySelect}
                                    onUpdateResult={setEditBattle}
                                    onDeleteBattle={handleDeleteBattle}
                                  />
                                ))}
                          </Tab>
                        )}

                        <Tab eventKey="leaderboard" title="Leaderboard">
                          {scores && scores.length && (
                            <Table striped bordered>
                              <thead>
                                <tr>
                                  <th>Player</th>
                                  <th
                                    style={{
                                      textAlign: "right",
                                      verticalAlign: "middle",
                                      writingMode: "vertical-rl",
                                    }}>
                                    Battles
                                  </th>
                                  <th
                                    style={{
                                      textAlign: "right",
                                      verticalAlign: "middle",
                                      writingMode: "vertical-rl",
                                    }}>
                                    Territories
                                  </th>
                                  <th
                                    style={{
                                      textAlign: "right",
                                      verticalAlign: "middle",
                                      writingMode: "vertical-rl",
                                    }}>
                                    Fighter OoA
                                  </th>
                                  <th
                                    style={{
                                      textAlign: "right",
                                      verticalAlign: "middle",
                                      writingMode: "vertical-rl",
                                    }}>
                                    Rating
                                  </th>
                                  <th
                                    style={{
                                      textAlign: "right",
                                      verticalAlign: "middle",
                                      writingMode: "vertical-rl",
                                    }}>
                                    Wealth
                                  </th>
                                  <th
                                    style={{
                                      textAlign: "right",
                                      verticalAlign: "middle",
                                      writingMode: "vertical-rl",
                                    }}>
                                    Reputation
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {scores
                                  .sort((a, b) => b.rating - a.rating)
                                  .map((s) => (
                                    <tr key={s.userId}>
                                      <td>{displayGangAndPlayer(s.userId)}</td>
                                      <td style={{ textAlign: "center" }}>
                                        {s.battles}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {s.territories}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {s.outOfActions}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {s.rating}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {s.wealth}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {s.reputation}
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </Table>
                          )}
                        </Tab>

                        <Tab eventKey="triumphs" title="Triumphs">
                          {scores && scores.length && (
                            <dl>
                              <Triumph
                                title="Dominator"
                                icon={faCity}
                                tooltip="Most Territories"
                                sortProp="territories"
                                players={campaign.players}
                                scores={scores}
                                resultCallback={(n) => `with ${n} territories`}
                              />
                              <Triumph
                                title="Slaughter"
                                icon={faSkullCrossbones}
                                tooltip="Most enemy fighter taken Out of Action and vehicles Wrecked"
                                sortProp="outOfActions"
                                players={campaign.players}
                                scores={scores}
                                resultCallback={(n) =>
                                  `having taken ${n} fighters Out of Actions`
                                }
                              />
                              <Triumph
                                title="Creditor"
                                icon={faCoins}
                                tooltip="Largest Wealth"
                                sortProp="wealth"
                                players={campaign.players}
                                scores={scores}
                                resultCallback={(n) =>
                                  `with a wealth of ${n}cr`
                                }
                              />
                              <Triumph
                                title="Warmonger"
                                icon={faPersonRifle}
                                tooltip="Most battles fought"
                                sortProp="battles"
                                players={campaign.players}
                                scores={scores}
                                resultCallback={(n) =>
                                  `having fought ${n} battles`
                                }
                              />
                              <Triumph
                                title="Powerbroker"
                                icon={faHandshake}
                                tooltip="Highest Reputation"
                                sortProp="reputation"
                                players={campaign.players}
                                scores={scores}
                                resultCallback={(n) => `with a rep of ${n}`}
                              />
                            </dl>
                          )}
                        </Tab>
                      </Tabs>
                    </>
                  )}

                  {selectedTerritoryId !== undefined &&
                    campaign.territories
                      .filter((t) => t.id === selectedTerritoryId)
                      .map((territory) => (
                        <Card key={territory.id}>
                          <Card.Header className="d-flex justify-content-between">
                            <span>{territory.name}</span>
                            <Button
                              size="sm"
                              onClick={() => handleSelectNone()}>
                              <FontAwesomeIcon icon={faClose} />
                            </Button>
                          </Card.Header>
                          <Card.Body className="fs-6">
                            <p>
                              <span className="text-info">Controlled By</span>{" "}
                              <ControlledBy
                                userId={territory.controlledByUserId}
                                players={campaign.players}
                              />
                            </p>
                            <TerritoryDetails territory={territory} />
                          </Card.Body>
                        </Card>
                      ))}
                </Col>
              </Row>
              <Row>
                <Col>
                  <Alert variant="warning" className="mt-2">
                    <FontAwesomeIcon icon={faCircleInfo} /> The territory tiles
                    are the work of{" "}
                    <a
                      href="https://www.myminifactory.com/users/PopovLaboratory"
                      target="_blank"
                      rel="noreferrer">
                      Papov
                    </a>
                    . If you like what you see and would ike to support the
                    artist, or would like to purchase these assets for 3D
                    printing, head over to his page on{" "}
                    <a
                      href="https://www.myminifactory.com/users/PopovLaboratory"
                      target="_blank"
                      rel="noreferrer">
                      MyMiniFactory
                    </a>
                    .
                  </Alert>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer className="text-end">
              {latestWeek &&
                !campaign.isEnded &&
                !replayMode &&
                authContext.currentUser?.userId === campaign.owner?.userId && (
                  <>
                    <Button
                      variant="secondary"
                      className="me-1"
                      disabled={availableTerritories.length === 0}
                      onClick={() => setShowAddBattle(true)}>
                      Record a Battle
                    </Button>
                    <Button
                      variant="primary"
                      className="me-1"
                      onClick={() => setShowNewWeek(true)}
                      disabled={
                        latestWeek.battles.some((b) => !b.playerScores) ||
                        latestWeek.weekNumber === 7
                      }>
                      Start new Campaign Week
                    </Button>
                    <Button
                      variant="danger"
                      className="me-1"
                      onClick={() => setShowEndDialogue(true)}
                      disabled={latestWeek.battles.some(
                        (b) => !b.playerScores,
                      )}>
                      End Campaign
                    </Button>
                  </>
                )}
              {!replayMode && (
                <Button variant="info" onClick={startCampaignReplay}>
                  Campaign Replay
                </Button>
              )}
              {replayMode && (
                <>
                  <Button
                    className="me-1"
                    disabled={campaignEventIndex === 0}
                    onClick={previousCampaignEvent}>
                    Prev
                  </Button>
                  <Button
                    className="me-1"
                    disabled={campaignEventIndex === campaignEvents.length - 1}
                    onClick={nextCampaignEvent}>
                    Next
                  </Button>
                  <Button onClick={() => setReplayMode(false)}>
                    End Replay
                  </Button>
                </>
              )}
            </Card.Footer>
          </Card>
          {latestWeek &&
            !campaign.isEnded &&
            authContext.currentUser?.userId === campaign.owner?.userId && (
              <>
                <AddBattleForm
                  show={showAddBattle}
                  players={campaign.players}
                  availableTerritories={availableTerritories}
                  onCancel={() => setShowAddBattle(false)}
                  onSubmit={handleAddBattle}
                />
                <BatteResultForm
                  battle={editBattle}
                  players={campaign.players}
                  scores={scores}
                  onCancel={() => setEditBattle(undefined)}
                  onSubmit={handleUpdateBattleResult}
                />
                <NewWeekForm
                  show={showNewWeek}
                  currentPhase={latestWeek.phase}
                  allTerritoriesClaimed={allTerritoriesClaimed}
                  onCancel={() => setShowNewWeek(false)}
                  onSubmit={handleStartNextWeek}
                />
                <EndCampaign
                  show={showEndDialogue}
                  onCancel={() => setShowEndDialogue(false)}
                  onSubmit={handleEndCampaign}
                />
              </>
            )}
        </>
      )}
    </>
  );
};

export default Home;
