import { Territory } from "../types";
import { BoonType } from "../types/boonType";
import { Gang } from "../types/gang";

export interface TerritoryDetailsProps {
  territory: Territory;
}

const TerritoryDetails = ({ territory }: TerritoryDetailsProps) => {
  return (
    <>
      {territory !== undefined && (
        <>
          <p className="fw-light lh-1 fst-italic">{territory.flavourText}</p>

          {territory.boon !== undefined &&
            territory.boon !== null &&
            territory.boon.length > 0 && (
              <>
                <h6 className="m-0 text-info">Territory Boon</h6>
                <p className="m-0">
                  This Territory grants the following Boon
                  {territory.boon.length > 1 ? "s" : ""}
                </p>
                {territory.boon.map((boon, index) => (
                  <p className="m-0 lh-1" key={index}>
                    <strong className="text-info">{BoonType[boon.type]}</strong>{" "}
                    {boon.text}
                  </p>
                ))}
              </>
            )}

          {territory.enhancedBoon !== undefined &&
            territory.enhancedBoon !== null &&
            territory.enhancedBoon.length > 0 &&
            territory.gang !== undefined && (
              <>
                <h6 className="m-0 mt-2 text-info">Enhanced Boon</h6>
                <p className="m-0">
                  This Territory grants {Gang[territory.gang]} gangs following
                  Boon
                  {territory.enhancedBoon.length > 1 ? "s" : ""}
                </p>
                {territory.enhancedBoon.map((boon, index) => (
                  <p className="m-0 lh-1" key={index}>
                    <strong className="text-info">{BoonType[boon.type]}</strong>{" "}
                    {boon.text}
                  </p>
                ))}
              </>
            )}
        </>
      )}
    </>
  );
};

export default TerritoryDetails;
