import { useEffect, useRef, useState, MouseEvent } from "react";
import {
  Accordion,
  Alert,
  Button,
  Card,
  Col,
  ListGroup,
  Row,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleInfo,
  faEdit,
  faEye,
  faPlus,
  faRandom,
} from "@fortawesome/free-solid-svg-icons";

import DomeItem from "../../components/domeItem";
import {
  DomeForm,
  TerritoryLocationForm,
  UnassignedTerritoryItem,
} from "../../components";

import { Dome, Player, Territory, TerritoryLocation } from "../../types";
import DefaultTerritories from "../../types/defaultTerritories";

import { useSetupContext } from "./setupContext";
import ThreeFactory from "../../services/threeFactory";
import ThreeService from "../../services/threeService";
import LoadingSpinner from "../../components/loadingSpinner";

const Map = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const threeRef = useRef<ThreeService | undefined>();
  const { value: wizardValue, update: updateWizard } = useSetupContext();

  const [loading, setLoading] = useState(true);
  const [selectedTerritoryId, setSelectedTerritoryId] = useState<
    number | undefined
  >();
  const [editDome, setEditDome] = useState<Dome | undefined>(undefined);
  const [editTerritoryLocation, setEditTerritoryLocation] = useState<
    TerritoryLocation | undefined
  >(undefined);

  useEffect(
    () => {
      return () => {
        if (threeRef.current) threeRef.current.dispose();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (!canvasRef.current) return;

    ThreeFactory.create(
      canvasRef.current,
      wizardValue.selectedTerritories,
    ).then((three) => {
      if (!canvasRef.current)
        throw new Error("Expecting canvasRef to have a value");

      three.updateSize();
      three.onSelect = setSelectedTerritoryId;

      const numberOfDomes = Math.ceil(
        (wizardValue.selectedTerritories.length + wizardValue.players.length) /
          7,
      );
      let domes = wizardValue.domes;
      if (domes.length === 0 || domes.length !== numberOfDomes) {
        domes = three.generateDomeLocations(numberOfDomes);
      } else {
        three.domes = domes;
      }

      let territoryLocations = wizardValue.campaignTerritories;
      if (
        territoryLocations.length === 0 ||
        territoryLocations.length !==
          wizardValue.selectedTerritories.length + wizardValue.players.length
      ) {
        territoryLocations = mapToTerritoryLocations(
          wizardValue.selectedTerritories,
          wizardValue.players,
        );
      } else {
        territoryLocations.forEach((territory) => {
          three.updateTerritory(
            territory,
            wizardValue.players.find(
              (p) => p.user.userId === territory.controlledByUserId,
            )?.color,
          );
        });
      }

      threeRef.current = three;
      wizardValue.isValid[2] =
        territoryLocations.length ===
          wizardValue.selectedTerritories.length + wizardValue.players.length &&
        !territoryLocations.some((t) => t.domeId === -1 && t.slot === -1);
      updateWizard({
        ...wizardValue,
        campaignTerritories: territoryLocations,
        domes: domes,
      });

      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvasRef]);

  const mapToTerritoryLocations = (
    territories: Territory[],
    players: Player[],
  ): TerritoryLocation[] => {
    const settlement = DefaultTerritories.find((t) => t.name === "Settlement");
    if (!settlement)
      throw new Error("Could not find Settement in DefaultTerritories");
    const territoryLocations = territories
      .map(
        (territory) =>
          ({
            ...territory,
            domeId: -1,
            slot: -1,
          } as TerritoryLocation),
      )
      .concat(
        players.map(
          (player, index) =>
            ({
              id: index * -1,
              name: `${player.gangName} Stronghold`,
              flavourText: ``,
              graphic: settlement.graphic,
              boon: settlement.boon,
              controlledByUserId: player.user.userId,
              domeId: -1,
              slot: -1,
            } as TerritoryLocation),
        ),
      );

    return territoryLocations;
  };

  const handleSelectNone = () => {
    if (!threeRef.current) return;
    setSelectedTerritoryId(undefined);
    threeRef.current.selectNone();
  };

  const handleDomeSelect = (domeId: number) => {
    if (!threeRef.current) return;
    setSelectedTerritoryId(undefined);
    threeRef.current.selectDome(domeId);
  };

  const handleTerritorySelect = (territoryId: number) => {
    if (!threeRef.current) return;
    setSelectedTerritoryId(territoryId);
    threeRef.current.selectTerritory(territoryId);
  };

  const handleTerritoryAdd = (
    territoryId: number,
    domeId: number,
    slot: number,
  ) => {
    if (!threeRef.current) return;

    const territory = wizardValue.campaignTerritories.find(
      (t) => t.id === territoryId,
    );
    if (!territory) return;
    territory.domeId = domeId;
    territory.slot = slot;

    threeRef.current.updateTerritory(
      territory,
      wizardValue.players.find(
        (p) => p.user.userId === territory.controlledByUserId,
      )?.color,
    );

    wizardValue.isValid[2] =
      wizardValue.campaignTerritories.length ===
        wizardValue.selectedTerritories.length + wizardValue.players.length &&
      !wizardValue.campaignTerritories.some(
        (t) => t.domeId === -1 && t.slot === -1,
      );
    updateWizard({
      ...wizardValue,
      campaignTerritories: wizardValue.campaignTerritories,
    });
  };

  const handleTerritoryRemove = (territoryId: number) => {
    if (!threeRef.current) return;

    const territory = wizardValue.campaignTerritories.find(
      (t) => t.id === territoryId,
    );
    if (!territory) return;
    territory.domeId = -1;
    territory.slot = -1;

    threeRef.current.updateTerritory(
      territory,
      wizardValue.players.find(
        (p) => p.user.userId === territory.controlledByUserId,
      )?.color,
    );

    wizardValue.isValid[2] =
      wizardValue.campaignTerritories.length ===
        wizardValue.selectedTerritories.length + wizardValue.players.length &&
      !wizardValue.campaignTerritories.some(
        (t) => t.domeId === -1 && t.slot === -1,
      );
    updateWizard({
      ...wizardValue,
      campaignTerritories: wizardValue.campaignTerritories,
    });
  };

  const randomiseTerritories = () => {
    if (!threeRef.current) return;

    const territories = threeRef.current.randomiseTerritories(
      wizardValue.campaignTerritories,
      wizardValue.players,
    );

    wizardValue.isValid[3] =
      territories.length ===
        wizardValue.selectedTerritories.length + wizardValue.players.length &&
      !territories.some((t) => t.domeId === -1 && t.slot === -1);
    updateWizard({
      ...wizardValue,
      campaignTerritories: territories,
    });
  };

  const handleDomeSave = (editValue: Dome) => {
    if (!threeRef.current) return;

    setEditDome(undefined);
    const dome = wizardValue.domes.find((d) => d.id === editValue.id);
    if (!dome)
      throw new Error(`Expected to find territory with Id ${editValue.id}`);
    dome.name = editValue?.name;

    threeRef.current.domes = wizardValue.domes;

    updateWizard({
      ...wizardValue,
    });
  };

  const handleTerritoryEdit = (territoryId: number) => {
    const territory = wizardValue.campaignTerritories.find(
      (t) => t.id === territoryId,
    );
    setEditTerritoryLocation(territory);
  };

  const handleTerritorySave = (editValue: TerritoryLocation) => {
    if (!threeRef.current) return;

    setEditTerritoryLocation(undefined);
    const territory = wizardValue.campaignTerritories.find(
      (t) => t.id === editValue.id,
    );
    if (!territory)
      throw new Error(`Expected to find territory with Id ${editValue.id}`);

    territory.name = editValue.name;
    territory.flavourText = editValue.flavourText;
    territory.controlledByUserId = editValue.controlledByUserId;

    threeRef.current.updateTerritory(
      territory,
      wizardValue.players.find(
        (p) => p.user.userId === territory.controlledByUserId,
      )?.color,
    );

    updateWizard({
      ...wizardValue,
    });
  };

  return (
    <>
      {loading && <LoadingSpinner />}
      <Card.Title>Customise the Map</Card.Title>
      <Card.Text className="text-body-tertiary fst-italic fs-5">
        Arrange the unassigned territories on your map by clicking the{" "}
        <FontAwesomeIcon icon={faPlus} /> button next to an empty slot, or
        randomly assign them by clicking the <FontAwesomeIcon icon={faRandom} />{" "}
        button. You can pan and zoom into the map using your mouse or click the{" "}
        <FontAwesomeIcon icon={faEye} /> next to each territory in the accordion
        on the right. Each territory can be customised changing its name and
        flavour text. You can also asign the territories to your players if you
        want to run a "Old Kingdoms" style campaign.
      </Card.Text>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Card.Title>Map</Card.Title>
            </Card.Header>
            <div>
              <Button
                className="px-2 py-0"
                style={{ height: 24, position: "absolute" }}
                onClick={handleSelectNone}>
                <FontAwesomeIcon icon={faEye} /> Reset
              </Button>
              <canvas ref={canvasRef} className="d-block w-100" height={400} />
            </div>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Header>
              <Card.Title className="d-flex justify-content-between">
                Domes
                <Button
                  className="text-info px-2 py-0"
                  style={{ height: 24 }}
                  onClick={randomiseTerritories}
                  title=" Randomly Assign">
                  <FontAwesomeIcon icon={faRandom} />
                </Button>
              </Card.Title>
            </Card.Header>
            <Card.Body className="p-0 overflow-auto" style={{ height: 400 }}>
              <Accordion
                flush
                className="user-select-none"
                style={{ fontSize: "0.8rem" }}>
                {wizardValue.domes.map((dome, index) => (
                  <Accordion.Item key={index} eventKey={index.toString()}>
                    <Accordion.Header>{dome.name}</Accordion.Header>
                    <Accordion.Body className="p-0">
                      <ListGroup variant="flush">
                        <ListGroup.Item className="user-select-none d-flex align-items-center">
                          <span className="flex-grow-1">{dome.name}</span>
                          <Button
                            className="px-2 py-0"
                            style={{ height: 24 }}
                            onClick={() => handleDomeSelect(dome.id)}>
                            <FontAwesomeIcon icon={faEye} />
                          </Button>
                          <Button
                            className="px-2 py-0"
                            style={{ height: 24 }}
                            onClick={() => setEditDome(dome)}>
                            <FontAwesomeIcon icon={faEdit} />
                          </Button>
                        </ListGroup.Item>
                        {Array.from(Array(7).keys()).map((i) => (
                          <DomeItem
                            key={i}
                            dome={dome}
                            slot={i + 1}
                            selectedTerritoryId={selectedTerritoryId}
                            onTerritorySelect={handleTerritorySelect}
                            onTerritoryAdd={handleTerritoryAdd}
                            onTerritoryRemove={handleTerritoryRemove}
                            onTerritoryEdit={handleTerritoryEdit}
                          />
                        ))}
                      </ListGroup>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
                <Accordion.Item eventKey="unassigned">
                  <Accordion.Header>
                    <div className="text-info">Unassigned Territories</div>
                  </Accordion.Header>
                  <Accordion.Body className="p-0">
                    {!wizardValue.campaignTerritories.some(
                      (t) => t.domeId === -1 && t.slot === -1,
                    ) && <Card.Body>None</Card.Body>}
                    <ListGroup variant="flush" style={{ fontSize: "0.8rem" }}>
                      {wizardValue.campaignTerritories
                        .filter((t) => t.domeId === -1 || t.slot === -1)
                        .map((territory, index) => (
                          <UnassignedTerritoryItem
                            key={index}
                            territory={territory}
                            selectedTerritoryId={selectedTerritoryId}
                            onTerritoryEdit={handleTerritoryEdit}
                          />
                        ))}
                    </ListGroup>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Alert variant="warning" className="mt-2">
            <FontAwesomeIcon icon={faCircleInfo} /> The territory tiles are the
            work of{" "}
            <a
              href="https://www.myminifactory.com/users/PopovLaboratory"
              target="_blank"
              rel="noreferrer">
              Papov
            </a>
            . If you like what you see and would ike to support the artist, or
            would like to purchase these assets for 3D printing, head over to
            his page on{" "}
            <a
              href="https://www.myminifactory.com/users/PopovLaboratory"
              target="_blank"
              rel="noreferrer">
              MyMiniFactory
            </a>
            .
          </Alert>
        </Col>
      </Row>

      <DomeForm
        dome={editDome}
        onCancel={() => setEditDome(undefined)}
        onSubmit={handleDomeSave}
      />

      <TerritoryLocationForm
        territory={editTerritoryLocation}
        onCancel={() => setEditTerritoryLocation(undefined)}
        onSubmit={handleTerritorySave}
      />
    </>
  );
};

export default Map;
