import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

import { useApiContext } from "../api/apiContext";
import { useAuthContext } from "../auth/authContext";

import LoadingSpinner from "../components/loadingSpinner";

import { CampaignSummary } from "../types";

const Home = () => {
  var apiContext = useApiContext();
  var authContext = useAuthContext();
  const navigate = useNavigate();

  const [campaigns, setCampaigns] = useState<CampaignSummary[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    apiContext
      .listCampaigns()
      .then((campaigns) => setCampaigns(campaigns))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (!authContext.token) {
      navigate(`/login?return=${encodeURI("/campaigns")}`);
    }
  }, [authContext.token, navigate]);

  return (
    <>
      {loading && <LoadingSpinner />}
      <Card>
        <Card.Header>
          <Card.Title>Campaigns</Card.Title>
        </Card.Header>
        <Card.Body>
          <Card.Text className="text-body-tertiary fst-italic fs-6">
            Listed here are all of the campaigns you are a participant in.
          </Card.Text>
          {campaigns.length > 0 && (
            <Table striped bordered>
              <thead>
                <tr>
                  <th>Campaign</th>
                  <th>Progress</th>
                  <th>Roll</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {campaigns.map((campaign) => (
                  <tr key={campaign.campaignId}>
                    <td>{campaign.name}</td>
                    <td>{campaign.isEnded ? "Ended" : "In Progress"}</td>
                    <td>{campaign.isArbitator ? "Arbitator" : "Player"}</td>
                    <td className="text-end">
                      <Button
                        size="sm"
                        href={`/campaigns/${campaign.campaignId}`}>
                        <FontAwesomeIcon icon={faEye} /> View
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
          {campaigns.length === 0 && (
            <p>
              There are no campaigns yet. Why not let your gaming group
              arbitrator know about this tool, or even start your own campaign.
            </p>
          )}
        </Card.Body>
        <Card.Footer className="text-end">
          <Button variant="primary" href="/setup">
            Create a new campaign
          </Button>
        </Card.Footer>
      </Card>
    </>
  );
};

export default Home;
