import { Button, Card } from "react-bootstrap";
import { Battle, Player, Territory } from "../types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faTrophy } from "@fortawesome/free-solid-svg-icons";

export interface BattleProps {
  battle: Battle;
  players: Player[];
  territories: Territory[];
  canEdit: boolean;
  isCurrentWeek: boolean;
  onSelectTerritory: (territoryId: number) => void;
  onDeleteBattle: (battle: Battle) => void;
  onUpdateResult: (battle: Battle) => void;
}

const BattleDetails = ({
  battle,
  players,
  territories,
  canEdit,
  isCurrentWeek,
  onSelectTerritory,
  onUpdateResult,
  onDeleteBattle,
}: BattleProps) => {
  var battlePlayers = battle.players.map((userId) =>
    players.find((player) => player.user.userId === userId),
  );

  return (
    <Card className="mb-2">
      <Card.Header className="d-flex justify-content-between">
        <span>
          {new Date(battle.date).toLocaleDateString()}{" "}
          {new Date(battle.date).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          })}
        </span>
        <div>
          {canEdit && isCurrentWeek && (
            <Button
              size="sm"
              onClick={() => onUpdateResult(battle)}
              className={!battle.playerScores ? "btn-info" : ""}
              title={
                !battle.playerScores ? "Records results" : "Alter results"
              }>
              <FontAwesomeIcon icon={faTrophy} />
            </Button>
          )}
          {canEdit && isCurrentWeek && !battle.playerScores && (
            <Button
              size="sm"
              className="ms-2"
              onClick={() => onDeleteBattle(battle)}>
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          )}
        </div>
      </Card.Header>
      <Card.Body className="fs-6">
        <p className="text-info">
          {battlePlayers.map((p, index) => (
            <span key={index}>
              {index !== 0 && <> vs </>}
              {p?.gangName} ({p?.user.name})
            </span>
          ))}
        </p>
        {battle.territoryId !== null && (
          <p
            style={{ cursor: "pointer" }}
            onClick={() => onSelectTerritory(battle.territoryId ?? 0)}>
            <span className="text-info">Staked Territory:</span>{" "}
            {territories.find((t) => t.id === battle.territoryId)?.name}
          </p>
        )}
        {battle.playerScores !== null && (
          <p>
            <span className="text-info">Winner:</span>{" "}
            {battlePlayers.find((p) => battle.winningPlayer === p?.user.userId)
              ?.gangName ?? "Draw"}
          </p>
        )}
      </Card.Body>
    </Card>
  );
};

export default BattleDetails;
