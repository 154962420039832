import * as axios from "axios";

import { ApiContext } from "./apiContext";
import { useAlertContext } from "../alert/alertContext";
import { useAuthContext } from "../auth/authContext";

import { AlertType } from "../types/alertMessage";
import {
  Battle,
  Campaign,
  CampaignSummary,
  CampaignWeek,
  User,
} from "../types";
import {
  BattleRequest,
  BattleResultRequest,
  CampaignRequest,
  CampaignWeekReqest,
} from "../types/requests";

export interface AlertProps {
  children?: React.ReactNode;
}

const ApiProvider = ({ children }: AlertProps) => {
  const alertContext = useAlertContext();
  const authContext = useAuthContext();

  const client = () => {
    const client = axios.default.create({
      baseURL: process.env.REACT_APP_API_BASE_URL,
      timeout: 31000,
      headers: {
        Accept: "application/json",
        authorization: authContext.token
          ? `Bearer ${authContext.token}`
          : undefined,
      },
    });

    client.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          authContext.logout();
        } else {
          console.log(error.message);
        }
        throw error;
      },
    );

    return client;
  };

  const login = async (email: string, password: string) => {
    return client()
      .post<string>("Auth/Login", {
        email,
        password,
      })
      .then((response) => {
        alertContext.showAlert({
          message: "Successfully logged in",
          type: AlertType.Success,
        });
        authContext.login(response.data);
      })
      .catch((error: axios.AxiosError) => {
        alertContext.showAlert({
          message: (error.response?.data as string) ?? error.message,
          type: AlertType.Error,
        });
      });
  };

  const forgottenPassword = async (email: string) => {
    return client()
      .post<string>("Auth/ForgottenPassword", {
        email,
      })
      .then(() => {
        alertContext.showAlert({
          message: "Please check your inbox",
          type: AlertType.Success,
        });
      })
      .catch((error: axios.AxiosError) => {
        alertContext.showAlert({
          message: (error.response?.data as string) ?? error.message,
          type: AlertType.Error,
        });
      });
  };

  const resetPassword = async (
    email: string,
    password: string,
    token: string,
  ) => {
    return client()
      .post<string>("Auth/ResetPassword", {
        email,
        password,
        token,
      })
      .then(() => {
        alertContext.showAlert({
          message: "Please check your inbox",
          type: AlertType.Success,
        });
      })
      .catch((error: axios.AxiosError) => {
        alertContext.showAlert({
          message: (error.response?.data as string) ?? error.message,
          type: AlertType.Error,
        });
      });
  };

  const logout = async () => {
    return client()
      .post("Auth/Logout")
      .then(() => {
        alertContext.showAlert({
          message: "Goodbye",
          type: AlertType.Success,
        });
        authContext.logout();
      })
      .catch((error: axios.AxiosError) => {
        alertContext.showAlert({
          message: (error.response?.data as string) ?? error.message,
          type: AlertType.Error,
        });
      });
  };

  const register = async (email: string, name: string, password: string) => {
    return client()
      .post("Auth/Register", { email, name, password })
      .then((response) => {
        alertContext.showAlert({
          message: "Successfully registered",
          type: AlertType.Success,
        });
        authContext.login(response.data);
      })
      .catch((error: axios.AxiosError) => {
        alertContext.showAlert({
          message: (error.response?.data as string) ?? error.message,
          type: AlertType.Error,
        });
      });
  };

  const updateAccount = async (
    email: string,
    name: string,
    password?: string,
  ) => {
    return client()
      .post("Auth/UpdateAccount", { email, name, password })
      .then((response) => {
        alertContext.showAlert({
          message: "Successfully updated",
          type: AlertType.Success,
        });
        authContext.login(response.data);
      })
      .catch((error: axios.AxiosError) => {
        alertContext.showAlert({
          message: (error.response?.data as string) ?? error.message,
          type: AlertType.Error,
        });
      });
  };

  const userList = async (email: string) => {
    return client()
      .get<User[]>(`User?emailFilter=${email}`)
      .then((response) => {
        return response.data;
      })
      .catch((error: axios.AxiosError) => {
        alertContext.showAlert({
          message: (error.response?.data as string) ?? error.message,
          type: AlertType.Error,
        });
        return [];
      });
  };

  const createCampaign = async (campaign: CampaignRequest) => {
    return client()
      .post<string>("Campaign", campaign)
      .then((response) => {
        alertContext.showAlert({
          message: "Successfully created campaign",
          type: AlertType.Success,
        });
        return response.data;
      })
      .catch((error: axios.AxiosError) => {
        alertContext.showAlert({
          message: (error.response?.data as string) ?? error.message,
          type: AlertType.Error,
        });

        return undefined;
      });
  };

  const listCampaigns = async () => {
    return client()
      .get<CampaignSummary[]>("Campaign")
      .then((response) => {
        return response.data;
      })
      .catch((error: axios.AxiosError) => {
        alertContext.showAlert({
          message: (error.response?.data as string) ?? error.message,
          type: AlertType.Error,
        });
        return [];
      });
  };

  const getCampaign = (campaignId: string) => {
    return client()
      .get<Campaign>(`Campaign/${campaignId}`)
      .then((response) => {
        return response.data;
      })
      .catch((error: axios.AxiosError) => {
        alertContext.showAlert({
          message: (error.response?.data as string) ?? error.message,
          type: AlertType.Error,
        });
        return undefined;
      });
  };

  const createBattle = async (campaignId: string, battle: BattleRequest) => {
    return client()
      .post<Battle>(`Campaign/${campaignId}/battle`, battle)
      .then((response) => {
        alertContext.showAlert({
          message: "Successfully created Battle",
          type: AlertType.Success,
        });
        return response.data;
      })
      .catch((error: axios.AxiosError) => {
        alertContext.showAlert({
          message: (error.response?.data as string) ?? error.message,
          type: AlertType.Error,
        });

        return undefined;
      });
  };

  const deleteBattle = async (campaignId: string, batteId: string) => {
    return client()
      .delete(`Campaign/${campaignId}/battle/${batteId}`)
      .then(() => {
        alertContext.showAlert({
          message: "Successfully deleted Battle",
          type: AlertType.Success,
        });
      })
      .catch((error: axios.AxiosError) => {
        alertContext.showAlert({
          message: (error.response?.data as string) ?? error.message,
          type: AlertType.Error,
        });
      });
  };

  const updateBatteResult = async (
    campaignId: string,
    batteId: string,
    battleResult: BattleResultRequest,
  ) => {
    return client()
      .post(`Campaign/${campaignId}/battle/${batteId}`, battleResult)
      .then(() => {
        alertContext.showAlert({
          message: "Successfully updated Battle",
          type: AlertType.Success,
        });
      })
      .catch((error: axios.AxiosError) => {
        alertContext.showAlert({
          message: (error.response?.data as string) ?? error.message,
          type: AlertType.Error,
        });
      });
  };

  const startNextCampaignWeek = async (
    campaignId: string,
    campaignWeek: CampaignWeekReqest,
  ) => {
    return client()
      .post<CampaignWeek>(`Campaign/${campaignId}/Week`, campaignWeek)
      .then((response) => {
        alertContext.showAlert({
          message: "Successfully started the next week",
          type: AlertType.Success,
        });
        return response.data;
      })
      .catch((error: axios.AxiosError) => {
        alertContext.showAlert({
          message: (error.response?.data as string) ?? error.message,
          type: AlertType.Error,
        });
        return undefined;
      });
  };

  const endCampaign = async (campaignId: string) => {
    return client()
      .delete(`Campaign/${campaignId}`)
      .then(() => {
        alertContext.showAlert({
          message: "Successfully ended the Campaign",
          type: AlertType.Success,
        });
      })
      .catch((error: axios.AxiosError) => {
        alertContext.showAlert({
          message: (error.response?.data as string) ?? error.message,
          type: AlertType.Error,
        });
      });
  };

  return (
    <ApiContext.Provider
      value={{
        login,
        forgottenPassword,
        resetPassword,
        logout,
        register,
        updateAccount,
        userList,
        createCampaign,
        listCampaigns,
        getCampaign,
        createBattle,
        deleteBattle,
        updateBatteResult,
        startNextCampaignWeek,
        endCampaign,
      }}>
      {children}
    </ApiContext.Provider>
  );
};

export default ApiProvider;
