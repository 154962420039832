import { createContext, useContext } from "react";
import User from "../types/user";

export interface AuthContextType {
  token: string | undefined;
  currentUser: User | undefined;
  login: (token: string) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const useAuthContext = () => {
  // get the context
  const context = useContext(AuthContext);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error("useAuthContext was used outside of its Provider");
  }

  return context;
};

export { AuthContext, useAuthContext };
