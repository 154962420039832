import { ChangeEvent } from "react";
import { FieldHookConfig, useField } from "formik";

import FormInput, { FormInputProps } from "./formInput";

export interface FormikFormInputProps extends FormInputProps {
  onChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const FormikFormInput = ({
  onChange,
  ...props
}: FormikFormInputProps & FieldHookConfig<string | undefined>) => {
  const [{ ...field }, { error, touched }, { setValue }] = useField(props);

  return (
    <FormInput
      {...field}
      {...props}
      onChange={(e) => {
        setValue(e.target.value === "" ? undefined : e.target.value);
        if (onChange) onChange(e);
      }}
      isValid={touched && !error}
      isInvalid={touched && error !== undefined}
    />
  );
};

export default FormikFormInput;
