import {
  AmbientLight,
  BackSide,
  ColorRepresentation,
  DirectionalLight,
  DoubleSide,
  MathUtils,
  Mesh,
  MeshLambertMaterial,
  MeshStandardMaterial,
  Object3D,
  PerspectiveCamera,
  PointLight,
  Raycaster,
  Scene,
  SpotLight,
  Vector2,
  Vector3,
  WebGLRenderer,
} from "three";
// @ts-ignore
import { OrbitControls } from "three/addons/controls/OrbitControls.js";

import { Dome, Player, TerritoryLocation } from "../types";
import { randomBetween, selectRandom } from "./helpers";
import { TerritoryGraphic } from "../types/territoryGraphic";
import SampleDomeNames from "../types/sampleDomeNames";

class ThreeService {
  private readonly _canvas: HTMLCanvasElement;
  private _renderer: WebGLRenderer | undefined;
  private readonly _scene: Scene;
  private readonly _controls: OrbitControls;
  private readonly _camera: PerspectiveCamera;
  private readonly _raycaster: Raycaster;
  private readonly _meshDictionary: { name: string; object: Mesh }[] = [];
  private _domes: Dome[] = [];
  private _startPoint: [number, number] | undefined;
  private readonly _clickDelta = 6;
  private _changeCounter = 0;
  private _lastChangeCounter = 0;
  private _sceneCenter: Vector3 | undefined;

  private _onPointerDownEvt = (event: MouseEvent) => this.onPointerDown(event);
  private _onPointerUpEvt = (event: MouseEvent) => this.onPointerUp(event);
  private _onResizeEvt = () => this.updateSize();
  private _handleRender = () => this.Update();

  public onSelect: ((territoryId: number | undefined) => void) | undefined;

  public get domes(): Dome[] {
    return this._domes;
  }
  public set domes(value: Dome[]) {
    this.domes.forEach((dome) => {
      this.deleteObjectFromScene(`domeBase_${dome.id}`);
      this.deleteObjectFromScene(`dome_${dome.id}`);
    });

    value.forEach((dome) => {
      const domeBaseMesh = this.getMesh("domeBase").clone();
      domeBaseMesh.castShadow = true;
      domeBaseMesh.receiveShadow = true;
      domeBaseMesh.material = new MeshLambertMaterial({
        map: (domeBaseMesh.material as MeshStandardMaterial).map?.clone(),
      });
      domeBaseMesh.translateX(dome.location.x);
      domeBaseMesh.translateY(dome.location.y);
      domeBaseMesh.translateZ(dome.location.z);
      this.addObjectToScene(`domeBase_${dome.id}`, domeBaseMesh);

      const domeMesh = this.getMesh("dome").clone();
      domeMesh.castShadow = true;
      domeMesh.receiveShadow = true;
      domeMesh.material = new MeshLambertMaterial({
        map: (domeMesh.material as MeshStandardMaterial).map?.clone(),
        side: BackSide,
      });
      domeMesh.translateX(dome.location.x);
      domeMesh.translateY(dome.location.y);
      domeMesh.translateZ(dome.location.z);
      this.addObjectToScene(`dome_${dome.id}`, domeMesh);
    });

    this.deleteObjectFromScene("pipes1");
    this.deleteObjectFromScene("pipes2");
    this.deleteObjectFromScene("pipes3");
    this.deleteObjectFromScene("pipes4");

    if (value.length > 1)
      this.addObjectToScene(
        "pipes1",
        this.createObjectInstance(
          this.getMesh("pipes1"),
          new Vector3(0, 0, 0),
          0xcd9479,
        ),
      );
    if (value.length > 2)
      this.addObjectToScene(
        "pipes2",
        this.createObjectInstance(
          this.getMesh("pipes2"),
          new Vector3(0, 0, 0),
          0xcd9479,
        ),
      );
    if (value.length > 3)
      this.addObjectToScene(
        "pipes3",
        this.createObjectInstance(
          this.getMesh("pipes3"),
          new Vector3(0, 0, 0),
          0xcd9479,
        ),
      );
    if (value.length > 4)
      this.addObjectToScene(
        "pipes4",
        this.createObjectInstance(
          this.getMesh("pipes4"),
          new Vector3(0, 0, 0),
          0xcd9479,
        ),
      );

    this._domes = value;

    const sumX = this.domes.reduce((a, b) => a + b.location.x, 0);
    const avgX = sumX / this.domes.length;
    const sumY = this.domes.reduce((a, b) => a + b.location.y, 0);
    const avgY = sumY / this.domes.length;
    this._sceneCenter = new Vector3(avgX, avgY, 0);
    if (this._camera.position)
      this._camera.position.set(this._sceneCenter.x, this._sceneCenter.y, 500);
    this._camera.lookAt(
      this._sceneCenter.x,
      this._sceneCenter.y,
      this._sceneCenter.z,
    );
    this._controls.target = this._sceneCenter;
    this._controls.update();
    this.Update();
  }

  constructor(
    canvas: HTMLCanvasElement,
    assets: { name: string; object: Mesh }[],
  ) {
    this._canvas = canvas;
    var width = canvas.width;
    var height = canvas.height;

    // Setup scene
    this._scene = new Scene();

    // Setup camera
    this._camera = new PerspectiveCamera(45, width / height);
    this._camera.up.set(0, 0, 1);
    this._scene.add(this._camera);

    // Setup Lighting
    const radius = 100;
    const lights = [];
    lights[0] = new AmbientLight(0xffffff, 0.2);
    lights[1] = new DirectionalLight(0xffffff, 0.5);
    lights[2] = new DirectionalLight(0xffffff, 0.5);
    lights[3] = new DirectionalLight(0xffffff, 0.5);
    lights[1].position.set(0, 2 * radius, 0);
    lights[2].position.set(2 * radius, -2 * radius, 2 * radius);
    lights[3].position.set(-2 * radius, -2 * radius, -2 * radius);
    this._scene.add(lights[0]);
    this._scene.add(lights[1]);
    this._scene.add(lights[2]);
    this._scene.add(lights[3]);

    // Setup renderer
    this._renderer = new WebGLRenderer({
      canvas: canvas,
      antialias: true,
    });
    this._renderer.setSize(width, height);
    this._renderer.useLegacyLights = false;
    this._renderer.shadowMap.enabled = true;

    // Setup obit controls
    this._controls = new OrbitControls(this._camera, canvas);
    this._controls.enablePan = false;
    this._controls.minDistance = 50;
    this._controls.maxDistance = 500;
    this._controls.maxPolarAngle = MathUtils.degToRad(75);
    this._controls.addEventListener("change", this._handleRender);

    this._raycaster = new Raycaster();

    // Setup events
    this._canvas.addEventListener("mousedown", this._onPointerDownEvt);
    this._canvas.addEventListener("mouseup", this._onPointerUpEvt);
    window.addEventListener("resize", this._onResizeEvt);

    this._meshDictionary = assets;

    this.animate();
  }

  public updateSize() {
    if (!this._renderer) return;

    const width = this._canvas.clientWidth;
    const height = this._canvas.clientHeight;
    this._camera.aspect = width / height;
    this._camera.updateProjectionMatrix();
    this._renderer.setSize(width, height);

    this.Update();
  }

  public dispose() {
    if (!this._renderer) return;

    this._canvas.removeEventListener("mousedown", this._onPointerDownEvt);
    this._canvas.removeEventListener("mouseup", this._onPointerUpEvt);
    window.removeEventListener("resize", this._onResizeEvt);

    this._renderer.dispose();
    this._renderer = undefined;
  }

  public generateDomeLocations(count: number): Dome[] {
    const locations = [
      new Vector3(0, 0, 0),
      new Vector3(0, 200, 0),
      new Vector3(173.2, 100, 0),
      new Vector3(-173.2, 100, 0),
      new Vector3(-173.2, -100, 0),
    ];

    let names = SampleDomeNames;
    const domes = locations.slice(0, count).map((location, index) => {
      const name = selectRandom(names);
      names = names.filter((v) => v !== name);
      return {
        id: index,
        name: name,
        location: location,
      };
    });

    this.domes = domes;

    this.Update();
    return domes;
  }

  public updateTerritory(
    territory: TerritoryLocation,
    color: string | undefined,
  ) {
    const territoryEdgeId = `territoryEdge_${territory.id}`;
    const territoryObjectId = `territoryObject_${territory.id}`;
    const territoryLightId = `territoryLight_${territory.id}`;

    this.deleteObjectFromScene(territoryEdgeId);
    this.deleteObjectFromScene(territoryObjectId);
    this.deleteObjectFromScene(territoryLightId);

    if (territory.domeId !== -1 && territory.slot !== -1) {
      const location = this.getDomeLocation(territory.domeId, territory.slot);

      const territoryEdge = this.createObjectInstance(
        this.getMesh("tileEdge"),
        location,
        color ?? 0xffffff,
      );
      this.addObjectToScene(territoryEdgeId, territoryEdge);

      const territoryObject = this.createObjectInstance(
        this.getMesh(TerritoryGraphic[territory.graphic] as string),
        location,
        0xcd9479,
      );
      //territoryObject.rotateZ((Math.PI / 3) * randomBetween(0, 5));
      this.addObjectToScene(territoryObjectId, territoryObject);

      const territoryLight = new PointLight(color ?? 0xffffff, 5, 40, 0.1);
      territoryLight.position.set(location.x, location.y, location.z + 20);
      this.addObjectToScene(territoryLightId, territoryLight);
    }

    this.Update();
  }

  public selectTerritory(territoryId: number) {
    const targetObject = this._scene.getObjectByName(
      `territoryObject_${territoryId}`,
    );
    if (!targetObject) return;

    // Change camera and orbit centre
    this._camera.position.set(
      targetObject.position.x,
      targetObject.position.y + 100,
      targetObject.position.z + 100,
    );
    this._camera.lookAt(
      targetObject.position.x,
      targetObject.position.y,
      targetObject.position.z,
    );
    this._controls.target = targetObject.position;

    // Spotlight
    let spotLight = this._scene.getObjectByName("SelectLight") as SpotLight;
    if (!spotLight) {
      spotLight = new SpotLight(0xfaf8be, 100, 70, Math.PI / 8, 1, 0.1);
      spotLight.name = "SelectLight";
      spotLight.castShadow = true;
      spotLight.shadow.mapSize = new Vector2(1024, 1024);
      spotLight.shadow.camera.far = 130;
      spotLight.shadow.camera.near = 40;
      this._scene.add(spotLight);
    }

    spotLight.position.set(
      targetObject.position.x + 20,
      targetObject.position.y + 20,
      targetObject.position.z + 60,
    );
    spotLight.target = targetObject;

    this.Update();
  }

  public selectDome(domeId: number) {
    this.deleteObjectFromScene("SelectLight");
    const location = this.getDomeLocation(domeId, 1);

    // Change camera and orbit centre
    this._camera.position.set(location.x, location.y + 150, location.z + 150);
    this._camera.lookAt(location.x, location.y, location.z);
    this._controls.target = location;

    this.Update();
  }

  public selectNone() {
    this.deleteObjectFromScene("SelectLight");

    // Change camera and orbit centre
    if (this._sceneCenter) {
      if (this._camera.position)
        this._camera.position.set(
          this._sceneCenter.x,
          this._sceneCenter.y,
          500,
        );
      this._camera.lookAt(
        this._sceneCenter.x,
        this._sceneCenter.y,
        this._sceneCenter.z,
      );
      this._controls.target = this._sceneCenter;
      this._controls.update();
    }

    this.Update();
  }

  public randomiseTerritories(
    territories: TerritoryLocation[],
    players: Player[],
  ): TerritoryLocation[] {
    let domeSlots: { domeId: number; slot: number }[] = [];
    for (let d = 0; d < this.domes.length; d++) {
      for (let s = 1; s <= 7; s++) {
        domeSlots.push({ domeId: d, slot: s });
      }
    }

    const territoryLocations = territories.map((territory) => {
      const selectedIndex = randomBetween(0, domeSlots?.length - 1);
      const domeSlot = domeSlots[selectedIndex];
      territory.domeId = domeSlot.domeId;
      territory.slot = domeSlot.slot;

      domeSlots = domeSlots.filter((ds) => ds !== domeSlot);

      this.updateTerritory(
        territory,
        players.find((p) => p.user.userId === territory.controlledByUserId)
          ?.color,
      );

      return territory;
    });

    this.Update();
    return territoryLocations;
  }

  private onPointerDown(event: MouseEvent) {
    this._startPoint = [event.pageX, event.pageY];
  }

  private onPointerUp(event: MouseEvent) {
    if (!this._startPoint) return;
    const diffX = Math.abs(event.pageX - this._startPoint[0]);
    const diffY = Math.abs(event.pageY - this._startPoint[1]);
    if (diffX > this._clickDelta || diffY > this._clickDelta) return;

    const pointer = new Vector2(
      (event.offsetX / this._canvas.width) * 2 - 1,
      -(event.offsetY / this._canvas.height) * 2 + 1,
    );

    this._raycaster.setFromCamera(pointer, this._camera);
    const intersects = this._raycaster.intersectObjects(
      this._scene.children,
      false,
    );
    const territories = intersects.filter((i) =>
      i.object.name.startsWith("territoryObject_"),
    );
    if (territories.length > 0) {
      const selectedItem = territories.sort(
        (i1, i2) => i1.distance - i2.distance,
      )[0];
      const territoryId = +selectedItem.object.name.replace(
        "territoryObject_",
        "",
      );
      this.selectTerritory(territoryId);
      if (this.onSelect) this.onSelect(territoryId);
    }
  }

  private getDomeLocation(domeId: number, slot: number): Vector3 {
    const y1Offset = 51.95;
    const y2Offset = 25.97;
    const xOffset = 45.02;

    const dome = this.domes.find((d) => d.id === domeId);
    if (!dome) throw new Error("Invalid Dome");

    switch (slot) {
      case 1:
        return new Vector3(dome.location.x, dome.location.y, dome.location.z);
      case 2:
        return new Vector3(
          dome.location.x,
          dome.location.y + y1Offset,
          dome.location.z,
        );
      case 3:
        return new Vector3(
          dome.location.x + xOffset,
          dome.location.y + y2Offset,
          dome.location.z,
        );
      case 4:
        return new Vector3(
          dome.location.x + xOffset,
          dome.location.y + y2Offset * -1,
          dome.location.z,
        );
      case 5:
        return new Vector3(
          dome.location.x,
          dome.location.y + y1Offset * -1,
          dome.location.z,
        );
      case 6:
        return new Vector3(
          dome.location.x + xOffset * -1,
          dome.location.y + y2Offset * -1,
          dome.location.z,
        );
      case 7:
        return new Vector3(
          dome.location.x + xOffset * -1,
          dome.location.y + y2Offset,
          dome.location.z,
        );
      default:
        throw new Error("Invalid Slot");
    }
  }

  private createObjectInstance(
    mesh: Mesh,
    location: Vector3,
    color: ColorRepresentation,
  ): Mesh {
    const instance = mesh.clone();
    instance.translateX(location.x);
    instance.translateY(location.y);
    instance.translateZ(location.z);
    instance.castShadow = true;
    instance.receiveShadow = true;
    if ((mesh.material as MeshStandardMaterial).map) {
      instance.material = new MeshLambertMaterial({
        map: (mesh.material as MeshStandardMaterial).map?.clone(),
        side: DoubleSide,
      });
    } else {
      instance.material = new MeshLambertMaterial({
        color: color,
        side: DoubleSide,
      });
    }
    return instance;
  }

  private addObjectToScene(id: string, object: Object3D) {
    object.name = id;
    this._scene.add(object);
  }

  private getObjectFromScene(id: string): Object3D | undefined {
    return this._scene.getObjectByName(id);
  }

  private deleteObjectFromScene(id: string) {
    const object = this.getObjectFromScene(id);
    if (object) {
      this._scene.remove(object);
    }
  }

  private getMesh(name: string): Mesh {
    const mesh = this._meshDictionary.find((f) => f.name === name)?.object;
    if (!mesh) throw new Error(`${name} mesh not found`);
    return mesh;
  }

  private animate() {
    if (!this._renderer) return;
    requestAnimationFrame(this.animate.bind(this));

    if (this._changeCounter !== this._lastChangeCounter) {
      this._renderer.render(this._scene, this._camera);
      this._lastChangeCounter = this._changeCounter;
    }
  }

  private Update() {
    this._changeCounter++;
    if (this._changeCounter === Number.MAX_VALUE) {
      this._changeCounter = 0;
    }
  }
}

export default ThreeService;
