import { useEffect, useState } from "react";
import styled from "styled-components";
import Alert from "react-bootstrap/Alert";

import { AlertMessage, AlertType } from "../types/alertMessage";
import { AlertContext } from "./alertContext";

export interface AlertProps {
  children?: React.ReactNode;
}

const AlertWrapper = styled.div`
  position: fixed;
  bottom: 5px;
  right: 15px;
`;

const AlertProvider = ({ children }: AlertProps) => {
  const [alert, showAlert] = useState<AlertMessage | undefined>(undefined);

  useEffect(() => {
    if (!alert) return;
    setTimeout(() => {
      showAlert(undefined);
    }, 5000);
  }, [alert]);

  return (
    // the Provider gives access to the context to its children
    <AlertContext.Provider value={{ showAlert }}>
      {children}
      {alert && (
        <AlertWrapper>
          <Alert
            variant={
              alert.type === AlertType.Success
                ? "success"
                : alert.type === AlertType.Information
                ? "info"
                : alert.type === AlertType.Warning
                ? "warning"
                : "danger"
            }>
            {alert.message}
          </Alert>
        </AlertWrapper>
      )}
    </AlertContext.Provider>
  );
};

export default AlertProvider;
