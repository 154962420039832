const SampleDomeNames = [
  "Chem Falls",
  "Forgotten Watch",
  "Desolation Terminal",
  "The Nether",
  "The Verdicts",
  "Withermarsh",
  "The Prospects",
  "Bilgewaters",
  "The Confines",
  "Direfall",
  "Taintside",
  "Perishton",
  "The Blanks",
  "The Dumps",
  "Beggar's End",
  "Vilewaters",
  "Killmoor",
  "The Projects",
  "Necrotown",
  "Enclave Outpost",
  "Outlaw's Outpost",
  "Purgatory Point",
  "Rat's Nest",
  "Scavenger's Station",
  "The Boneyard",
  "The Desolate Outskirts",
  "The Rusty Ruins",
  "Bloodspike Bastion",
  "Goliath's Graveyard",
  "Mournful Marshes",
  "Outcast Outpost",
  "Ransacker's Roost",
  "The Mutant's Den",
  "Hell's Haven",
  "Pomaria",
  "Courage Fall",
  "Remembrance",
  "Men's Rest",
  "Omnadon",
  "Firebend",
  "Ashheap",
  "Malicity",
  "Asturica",
  "Everwinter",
  "Cavity",
  "Eternity",
  "Demonia",
  "Hazardine",
  "Murkville",
  "Forsaken Falls",
  "Terminus",
  "Charington",
  "Tribulation",
  "Ashbourne",
  "Marsonia",
  "Anvilorum",
  "Marduk's Grief",
  "Malaise",
  "Sabbat's Rapture",
  "Ceaseton",
  "Mina",
  "Promise",
  "Mictlan",
  "Asphodel",
  "Malady",
  "Carnage",
  "Guilder's Crossing",
  "Sinister Springs",
  "The Demolition District",
  "The Gauntlet",
  "The Shadowed Shores",
  "Dead Man's Gulch",
  "Doom's Crossing",
  "Ferron's Forge",
  "Hive's End",
  "Sumpside Junction",
  "Techno-Temple",
];

export default SampleDomeNames;
