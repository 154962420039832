import { useState } from "react";
import { Button, Card, Form, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";

import { PlayerForm } from "../../components";

import { GangOrCustomGang, Player } from "../../types";
import { Gang, GangOrOther } from "../../types/gang";

import { enumEquals, uuidv4 } from "../../services/helpers";

import { useSetupContext } from "./setupContext";

const Players = () => {
  const { value: wizardValue, update: updateWizard } = useSetupContext();
  const [editPlayer, setEditPlayer] = useState<Player | undefined>(undefined);
  const maxPlayers = 8;
  const colors = [
    "#E74C3C", // Light Red
    "#9B59B6", // Light Purple
    "#3498DB", // Light Blue
    "#1ABC9C", // Light Green
    "#F1C40F", // Light Yellow
    "#E67E22", // Light Orange
    "#641E16", // Dark Red
    "#512E5F", // Deep Purple
    "#154360", // Dark Blue
    "#0B5345", // Dark Green
    "#7D6608", // Dark Yellow
    "#873600", // Dark Orange
  ];

  const setSandboxMode = (value: boolean) => {
    wizardValue.isValid[1] = false;
    updateWizard({
      ...wizardValue,
      players: [],
      sandboxMode: value,
    });
  };

  const handleAddPlayer = () => {
    const players = wizardValue.players.length;
    const newPlayer: Player = {
      user: wizardValue.sandboxMode
        ? {
            userId: uuidv4(),
            email: `user${players + 1}@test.com`,
            name: `User ${players + 1}`,
          }
        : {
            userId: "",
            email: "",
            name: "",
          },
      gangName: wizardValue.sandboxMode ? `Gang ${players + 1}` : "",
      gang: {
        houseGang: GangOrOther.Escher,
      },
      color: colors[wizardValue.players.length],
    };
    setEditPlayer(newPlayer);
  };

  const handleDeletePlayer = (player: Player) => {
    const players = wizardValue.players.filter(
      (p) => p.user.userId !== player.user.userId,
    );
    wizardValue.isValid[1] = players.length >= 3;
    updateWizard({
      ...wizardValue,
      players: players,
      campaignTerritories: [],
    });
  };

  const handleSave = (player: Player) => {
    const players = wizardValue.players
      .filter((p) => p.user.userId !== player.user.userId)
      .concat(player);
    wizardValue.isValid[1] = players.length >= 3;
    updateWizard({
      ...wizardValue,
      players: players,
      campaignTerritories: [],
    });
    setEditPlayer(undefined);
  };

  const displayGang = (gang: GangOrCustomGang) => {
    if (
      gang.houseGang !== undefined &&
      !enumEquals(GangOrOther, gang.houseGang, GangOrOther.Other)
    )
      return GangOrOther[gang.houseGang];
    const territoryGang =
      gang.territoryGang !== undefined ? `(${Gang[gang.territoryGang]}*)` : "";
    return `${gang.customGangType} ${territoryGang}`;
  };

  return (
    <>
      <Card.Title>Players</Card.Title>
      <Card.Text className="text-body-tertiary fst-italic fs-5">
        Add players to the campaign. A Dominion campaign requires a minimum of 3
        players. This tool will support a maximum of {maxPlayers} players. Your
        players will need to be registered on HiveDominion, so please ask them
        to create their accounts{" "}
        <a
          href="https://hivedominion.co.uk/login"
          target="_blank"
          rel="noreferrer">
          here
        </a>
        .
      </Card.Text>
      <Card.Text className="text-body-tertiary fst-italic fs-5">
        Once your players are registered, click "Add Player" and fill out the
        form, entering the email address of each player, selecting their gang
        and naming them. In Dominion there are special boons for the 6 house
        gangs. You can add any other gang as a custom gang, but you need to
        indicate which of the 6 house gangs it will count as for the purposes of
        boons.
      </Card.Text>
      <Card.Text className="text-body-tertiary fst-italic fs-5">
        If you want to play with the tool without adding players, why not try
        out Sandbox Mode? In Sandbox Mode, you don't need to add real players,
        but you cannot save the campaign at the end.
      </Card.Text>
      <div>
        <Form.Check.Input
          type="checkbox"
          onChange={(e) => setSandboxMode(e.target.checked)}
          checked={wizardValue.sandboxMode}
        />{" "}
        <Form.Check.Label>Sandbox Mode</Form.Check.Label>
      </div>
      <Table striped bordered>
        <thead>
          <tr>
            <th className="px-0"></th>
            <th>Name</th>
            <th>Email Address</th>
            <th>Gang Name</th>
            <th>House/Gang</th>
            <th style={{ width: "132px" }}></th>
          </tr>
        </thead>
        <tbody>
          {wizardValue.players.map((player) => (
            <tr key={player.user.userId}>
              <td className="px-0">
                <div
                  style={{
                    backgroundColor: player.color,
                    width: 25,
                    height: 25,
                  }}></div>
              </td>
              <td>{player.user.name}</td>
              <td>{!wizardValue.sandboxMode && player.user.email}</td>
              <td>{player.gangName}</td>
              <td>{displayGang(player.gang)}</td>
              <td>
                <Button size="sm" onClick={() => setEditPlayer(player)}>
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
                <Button size="sm" onClick={() => handleDeletePlayer(player)}>
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="d-flex justify-content-between">
        <p className="text-body-tertiary fst-italic fs-6">
          *For the purpose of Territory Enhanced Boons
        </p>
        {wizardValue.players.length < 3 && (
          <p className="text-danger align-self-center m-0">
            A Dominion Campaign requires at least 3 players
          </p>
        )}
        <Button
          variant="info"
          onClick={handleAddPlayer}
          disabled={wizardValue.players.length === maxPlayers}>
          <FontAwesomeIcon icon={faPlus} /> Add Player
        </Button>
      </div>
      <PlayerForm
        player={editPlayer}
        sandboxMode={wizardValue.sandboxMode}
        onCancel={() => setEditPlayer(undefined)}
        onSubmit={handleSave}
      />
    </>
  );
};

export default Players;
