import { Territory } from "../types";
import { Gang } from "../types/gang";

export interface TerritoryHeaderProps {
  territory: Territory;
}

const TerritoryHeader = ({ territory }: TerritoryHeaderProps) => {
  return (
    <h5 className="m-0">
      {territory.name}{" "}
      <span style={{ fontSize: "0.8rem" }}>
        {territory.gang !== undefined ? `(${Gang[territory.gang]})` : ""}
      </span>
    </h5>
  );
};

export default TerritoryHeader;
