const getEnumKeys = <O extends object, K extends keyof O = keyof O>(
  enumToDeconstruct: O,
): K[] => Object.keys(enumToDeconstruct).filter((k) => Number.isNaN(+k)) as K[];

const getEnumValues = <O extends object, K extends keyof O = keyof O>(
  enumToDeconstruct: O,
): K[] =>
  Object.keys(enumToDeconstruct)
    .filter((k) => !Number.isNaN(+k))
    .map((k) => +k) as K[];

const enumEquals = <T>(e: any, e1: T, e2: T): boolean => {
  const v1 = enumValue(e, e1);
  return v1 === enumValue(e, e2, typeof v1);
};

const enumValue = <T>(enumType: any, value: T, validType?: string) => {
  let v = enumType[value];
  if (!validType) {
    return v;
  }
  if (typeof v !== validType) {
    v = enumType[v];
  }
  if (typeof v !== validType) {
    v = enumType[v];
  }
  return v || null;
};

const groupBy = <T>(arr: T[], fn: (item: T) => any) => {
  return arr.reduce<Record<string, T[]>>((prev, curr) => {
    const groupKey = fn(curr);
    const group = prev[groupKey] || [];
    group.push(curr);
    return { ...prev, [groupKey]: group };
  }, {});
};

const randomBetween = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

const selectRandom = <T>(data: T[]): T => {
  return data[randomBetween(0, data.length - 1)];
};

const clone = <T>(thing: T): T => {
  return JSON.parse(JSON.stringify(thing)) as T;
};

const uuidv4 = () => {
  var d = new Date().getTime(); //Timestamp
  var d2 =
    (typeof performance !== "undefined" &&
      performance.now &&
      performance.now() * 1000) ||
    0; //Time in microseconds since page-load or 0 if unsupported

  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
};

export {
  clone,
  getEnumKeys,
  getEnumValues,
  enumEquals,
  enumValue,
  groupBy,
  randomBetween,
  selectRandom,
  uuidv4,
};
